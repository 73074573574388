/* eslint-disable */
// 临时在一段代码中取消eslint检查，可以设置/* eslint-disable */
import plugins from '../js/plugin';

//生成图片带签名的地址
function cover_signatureUrl(filename, image_path_url_process, thumbnail_image_url_process, that) {
    return new Promise(function (resolve, reject) {
        let content = {}
        content.type = 'get_cover_image_signed_url'
        content.phone = that.$store.state.accept_data.phone
        content.session = that.$store.state.session
        content.filename = filename
        content.process = image_path_url_process
        content.thumbnail_image_url_process = thumbnail_image_url_process
        let param = new URLSearchParams()
        param.append('username', 'dkeiYdjcnccnswwwoepsa')
        param.append('content', JSON.stringify(content))
        that.$ajax({
                url: '/zhitui/zuomian_oss',
                method: 'post',
                data: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                // console.log('get signatureUrl response:', response.data)
                if (response.status === 200 && typeof (response.data) === 'object') {

                    if (response.data.result && response.data.result == '登录过期') {
                        that.$message({
                            showClose: true,
                            message: '登录过期',
                            type: 'error'
                        })
                        that.$router.replace('/login')
                    }
                    return resolve(response.data)
                } else {
                    console.log('获取用户信息失败')
                    return reject('error')
                }
            })
            .catch((response) => {
                console.log('error:', response)
                return reject('error')
            })
    })
};

// 裁剪框点击保存触发
function cover_image_save(that) {
    return new Promise(function (resolve, reject) {
        console.log('that.cut_dialog.temp_graphic_introduction_cover:', that.cut_dialog.temp_graphic_introduction_cover)
        if (that.cut_dialog.temp_graphic_introduction_cover.original_image_url.length == 0) {
            that.$confirm('请先选择封面', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '',
                    showCancelButton: false,
                    closeOnClickModal: false,
                    type: 'warning'
                }).then(() => {})
                .catch(() => {})
            return resolve({})
        }
        //锁屏
        const loading = that.$loading({
            lock: true,
            text: '保存中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })
        var save_img_info = that.$refs.cover_img.getBoundingClientRect()
        var cover_img_container = that.$refs.cover_img_container.getBoundingClientRect()
        var w, h, x, y
        that.cut_dialog.temp_graphic_introduction_cover.style.width = w = Math.floor(save_img_info.width)
        that.cut_dialog.temp_graphic_introduction_cover.style.height = h = Math.floor(save_img_info.height)
        that.cut_dialog.temp_graphic_introduction_cover.style.top = y = Math.floor(save_img_info.top - cover_img_container.top)
        that.cut_dialog.temp_graphic_introduction_cover.style.left = x = Math.floor(save_img_info.left - cover_img_container.left)
        // that.print('that.cut_dialog:', that.cut_dialog.graphic_introduction_cover.style)

        let file = that.cut_dialog.temp_graphic_introduction_cover.original_image_url
        let file_temp = file.split('?')[0] //截取参数前面的文件路径
        // 这里可能来自临时文件夹，也可能来自正式文件夹
        var file_name = that.cut_dialog.temp_graphic_introduction_cover.from + '/' + file_temp.substr(file_temp.lastIndexOf('/') + 1)
        // var file_name = 'temp/' + file_temp.substr(file_temp.lastIndexOf('/') + 1)
        // limit:指定当目标缩放图大于原图时是否进行缩放。1（默认值）：表示不按指定参数进行缩放，直接返回原图。0：按指定参数进行缩放。
        // 将宽高为cw,ch的截图转换为980和300的图
        let cw = that.cut_dialog.img_container.width
        let ch = that.cut_dialog.img_container.height
        let height_w980 = Math.floor(980 * (ch / cw))
        let height_w300 = Math.floor(300 * (ch / cw))
        let image_path_url_process = `image/resize,w_${w},h_${h},limit_0/crop,x_${-x},y_${-y},w_${cw},h_${ch}/resize,w_980,h_${height_w980},limit_0`
        let thumbnail_image_url_process = `image/resize,w_${w},h_${h},limit_0/crop,x_${-x},y_${-y},w_${cw},h_${ch}/resize,w_300,h_${height_w300},limit_0`
        cover_signatureUrl(file_name, image_path_url_process, thumbnail_image_url_process, that).then(value => {
            setTimeout(() => {
                loading.close()
                that.$message({
                    message: '保存成功',
                    type: 'success'
                })
            }, 500)
            that.cut_dialog.dialogTableVisible = false
            // console.log('signatureUrl return:', value)
            that.cut_dialog.temp_graphic_introduction_cover.thumbnail_image_url = value.thumbnail_image_url
            that.cut_dialog.temp_graphic_introduction_cover.path = value.url
            that.cut_dialog.temp_graphic_introduction_cover.type = image_path_url_process
            // that.print('that.cut_dialog:', that.cut_dialog.graphic_introduction_cover)
            // 在覆盖graphic_introduction_cover信息之前，判断graphic_introduction_cover信息是否来自temp,
            // 如果是且不同名则先删除graphic_introduction_cover图片。同名可能只是调整裁剪参数，这是允许的。
            if (that.cut_dialog.graphic_introduction_cover.from == 'temp' && that.cut_dialog.temp_graphic_introduction_cover.original_image_url != that.cut_dialog.graphic_introduction_cover.original_image_url) {
                let file = that.cut_dialog.graphic_introduction_cover.original_image_url
                let file_temp = file.split('?')[0] //截取参数前面的文件路径
                let file_name = 'temp/' + file_temp.substr(file_temp.lastIndexOf('/') + 1) //这里肯定是来自temp文件夹
                plugins.oss_delete(that, file_name).then(() => {
                    return resolve({
                        result: 'ok',
                        data: value
                    })
                }).catch(() => {
                    return resolve({
                        result: 'ok',
                        data: value
                    })
                })
            } else {
                return resolve({
                    result: 'ok',
                    data: value
                })
            }
        }).catch((err) => {
            that.cut_dialog.dialogTableVisible = false
            setTimeout(() => {
                loading.close()
                that.$message({
                    message: '保存失败',
                    type: 'error'
                })
            }, 500)
            console.log('signatureUrl err:', err)
            return reject({
                result: 'error'
            })
        })
    })

    /*
      graphic_introduction_cover: {
        path: '',        //裁剪大图，直接显示
        from: '',
        type: 'image',
        percent: 0,
        size: 0,
        name: '',
        thumbnail_image_url: '',  // 300裁剪缩略图，直接显示
        original_image_url: '',   // 980原图
        style: {    //裁剪信息
          width: '',
          height: '',
          left: 0,
          top: 0
        },
      }
      */
};

// 裁剪框点击选择封面触发
// 执行此函数表示已经成功选取了新的文件即将处理，处理之前需要对之前赋值给temp_graphic_introduction_cover的文件信息操作：
// a.如果temp_graphic_introduction_cover的文件来自temp文件，由于temp_graphic_introduction_cover来自graphic_introduction_cover的赋值，
// 所以需要分分两种情况考虑：
// 第一种是和graphic_introduction_cover文件信息不同则直接删除。这种情况发生在graphic_introduction_cover初始值为空，或者不为空的情况下
// 在裁剪框进行第二次或者更多的选择文件操作时。
// 第二种是和graphic_introduction_cover文件信息相同，表示graphic_introduction_cover初始值不为空，需要点击裁剪框保存时处理，如果不
// 点击保存，则不能删除。如果点击了保存，判断二者信息是否相同，如果不同，且graphic_introduction_cover信息来自temp,则删除graphic_introduction_cover图片。
// b.如果是以编辑状态进入页面首次赋值，那么文件就来自正式文件
// 夹，则不需要做任何处理，直到退出页面时再做处理。退出页面时如果未保存，则直接忽略；如果退出时点击了保存，则比较原始信息和graphic_introduction_cover
// 中的信息是否相同，相同表示未修改封面；不同则表示修改了封面，需要删除原正式文件夹的信息，以temp文件夹新封面信息代替。
function delete_cover_image(that) {
    return new Promise(function (resolve) {
        // that.print('temp_graphic_introduction_cover:', that.cut_dialog.temp_graphic_introduction_cover)
        if (that.cut_dialog.temp_graphic_introduction_cover.from == 'temp' && that.cut_dialog.temp_graphic_introduction_cover.original_image_url != that.cut_dialog.graphic_introduction_cover.original_image_url) {
            let file = that.cut_dialog.temp_graphic_introduction_cover.original_image_url
            let file_temp = file.split('?')[0] //截取参数前面的文件路径
            let file_name = 'temp/' + file_temp.substr(file_temp.lastIndexOf('/') + 1) //这里肯定是来自temp文件夹
            plugins.oss_delete(that, file_name)
        }
        return resolve({})
    })
};

function enter_function(that, type, param) {
    // 待完善功能1：长图比例超过1：8会很模糊，需要判断拒绝 ///////////////////////////////已完善
    // 待完善功能2: 一边非常短的话(小于某个阙值)，裁图会报错：https://help.aliyun.com/knowledge_detail/32228.html，也可以并作问题1判断处理 /////已完善
    // 待补充
    switch (type) {
        case 'cover_image_save': //保存
            cover_image_save(that).then(() => {
                Object.assign(that.cut_dialog.graphic_introduction_cover, that.cut_dialog.temp_graphic_introduction_cover)
            })
            break;
        default:
    }
};

export default {
    enter_function,
    delete_cover_image
};