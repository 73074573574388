<template>
  <div class="login">
    <div class="login_container wow fadeInLeft">
      <div class="login_form" v-if="!is_choose_account">
        <div class="login_form_title">帐号登录</div>
        <div>
          <div class="col wow fadeInLeft" data-wow-delay="0s">
            <div class="col_area">86<span></span></div>
            <input
              class="col_input"
              autocomplete="off"
              placeholder="请输入手机号码"
              type="phone"
              v-model="phone_number"
              style="padding-left: 80px"
            />
          </div>
          <div class="col wow fadeInLeft" data-wow-delay="0.05s">
            <input
              class="col_input_password"
              autocomplete="new-password"
              placeholder="请输入密码"
              type="password"
              maxlength="16"
              v-model="password"
            />
            <span class="col_forget" @click="forget()">忘记密码?</span>
          </div>
          <div class="col wow fadeInLeft">
            <button class="col_button" @click="tologin">
              <span>立即登录</span>
            </button>
          </div>
          <div class="col wow fadeInLeft" data-wow-delay="0.1s">
            <div>
              <a href="javascript:void(0)" @click="zhuce"
                ><i class="iconfont iconicon_zhuce"></i><span>注册账号</span></a
              >
            </div>
            <div>
              <a target="_blank" @click="weixin_login"
                ><i class="iconfont iconicon_weixin"></i
                ><span>微信登录</span></a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="login_form" v-if="is_choose_account">
        <div
          class="login_form_title animate__animated animate__fadeInLeft"
          style="margin-bottom: 0px"
        >
          选择登录账号
        </div>
        <div v-show="primary_account.length != 0">
          <div
            class="login_identity animate__animated animate__fadeInLeft"
            style="margin-top: 30px"
          >
            管理员身份账号
          </div>
          <div
            v-for="(item, index) in primary_account"
            v-bind:key="index"
            class="login_account_item animate__animated animate__fadeInLeft"
            @click.stop="choose_account(item, 'from_primary_account')"
          >
            <div class="login_item_img">
              <el-image
                style="width: 100%; height: 100%; overflow: hidden"
                :src="item.applet.authorizer_info.head_img"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div
              style="
                margin-left: 10px;
                margin-top: 2px;
                display: inline-block;
                vertical-align: top;
              "
            >
              <div
                style="
                  font-size: 14px;
                  color: rgb(51, 51, 51);
                  max-width: 180px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{
                  item.applet.authorizer_info.nick_name
                    ? item.applet.authorizer_info.nick_name
                    : '未命名小程序'
                }}
              </div>
              <div
                style="
                  font-size: 12px;
                  color: rgb(170, 170, 170);
                  margin-top: 4px;
                "
              >
                最近登录：{{
                  last_login_time(item.main_account_info.bms_last_login_time)
                }}
              </div>
            </div>
            <div
              style="
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translate3d(0, -50%, 0);
              "
            >
              <i
                class="el-icon-right"
                style="font-size: 17px; color: #a3a0a0"
              ></i>
            </div>
          </div>
        </div>
        <div v-show="child_account.length != 0">
          <div
            class="login_identity animate__animated animate__fadeInLeft"
            style="margin-top: 30px"
          >
            他人授权账号
          </div>
          <div
            v-for="(item, index) in child_account"
            v-bind:key="index"
            class="login_account_item animate__animated animate__fadeInLeft"
            @click.stop="choose_account(item, 'from_child_account')"
          >
            <div class="login_item_img">
              <el-image
                style="width: 100%; height: 100%; overflow: hidden"
                :src="item.applet.authorizer_info.head_img"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div
              style="
                margin-left: 10px;
                margin-top: 2px;
                display: inline-block;
                vertical-align: top;
              "
            >
              <div
                style="
                  font-size: 14px;
                  color: rgb(51, 51, 51);
                  max-width: 180px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{
                  item.applet.authorizer_info.nick_name
                    ? item.applet.authorizer_info.nick_name
                    : '未命名小程序'
                }}
              </div>
              <div
                style="
                  font-size: 12px;
                  color: rgb(170, 170, 170);
                  margin-top: 4px;
                "
              >
                最近登录：{{
                  last_login_time(item.child_account_info.bms_last_login_time)
                }}
              </div>
            </div>
            <div
              style="
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translate3d(0, -50%, 0);
              "
            >
              <i
                class="el-icon-right"
                style="font-size: 17px; color: #a3a0a0"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
import plugins from '../assets/js/plugin'
export default {
  name: 'login',
  data () {
    return {
      bg_src: 'https://miya-1253902085.cos.ap-guangzhou.myqcloud.com/bms/formal_edit/bg.png', //背景图地址
      phone_number: '',
      password: '',
      is_choose_account: false,
      primary_account: [],
      child_account: [],
    }
  },
  // vue实例的数据对象data在钩子函数created()执行的时候已经有了
  created () {
    // console.log('---------------浏览器重新进入登录页面时，如果之前忘记退出登录，也重置，需要重新登录-------------')
    this.$store.commit('SET_TOKEN', '')
    /*
    // 如果存在session，直接跳转，至于过没过期，在具体操作时再做判断
    if (window.sessionStorage.getItem('session')) {
      this.$router.replace('')
    }
    */
  },
  mounted () {
    // 参考 https://www.jianshu.com/p/eff637c7ffc9
    var wow = new WOW({
      live: false
    })
    wow.init()
  },
  computed: {
    last_login_time () {
      return function (login_time) {
        let time = new Date(login_time)
        let create_time_format = plugins.formatTime_ymdhms(time)
        return create_time_format
      }
    },
  },
  methods: {
    choose_account (item, from) {
      this.print('choose_account item:', item)
      let that = this
      this.$store.commit('controlpanel_menu_isActive_index', 0)
      this.$store.commit('controlpanel_template_name', item.main_account_info.bms_menu[0].template)
      this.$store.commit('controlpanel_current_menu_son', '')
      this.$store.commit('SET_accept_data', item.main_account_info)
      this.$store.commit('SET_accept_data_child_account', item.child_account_info)

      // 刷新所选择的账号的登录时间
      let _id = (from == 'from_child_account' ? item.child_account_info._id : item.main_account_info._id)
      plugins.requestLogin(that, this.phone_number, this.password, 'refresh_time', _id).then(() => {
      }).catch((error) => {
        console.log("catch error:", error)
      })
      this.$router.replace('/controlpanel')
    },
    forget () {
      this.$message({
        showClose: true,
        message: '请联系管理员重设密码',
        type: 'info'
      })
      return
    },
    tologin () {
      var that = this
      if (this.phone_number.length === 0) {
        this.$message({
          showClose: true,
          message: '手机号不能为空',
          type: 'error'
        })
        return
      }
      if (this.password.length === 0) {
        this.$message({
          showClose: true,
          message: '密码不能为空',
          type: 'error'
        })
        return
      }
      // 如果完整引入了 Element，那么 Vue.prototype 上会有一个全局方法 $loading，它的调用方式为：this.$loading(options)
      var loadingInstance = this.$loading()
      // console.log('loading...')
      // 测试手机号：test 密码：test
      plugins.requestLogin(that, this.phone_number, this.password).then(() => {
        loadingInstance.close()
        // this.$router.replace('/controlpanel')
      }).catch((error) => {
        loadingInstance.close()
        console.log("catch error:", error)
      })

    },
    zhuce () {
      this.$message({
        showClose: true,
        message: '请联系管理员开通权限',
        type: 'success'
      })
    },
    weixin_login () {
      this.$message({
        showClose: true,
        message: '尚未绑定帐号',
        type: 'success'
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../assets/scss/icon.scss';
@import '../assets/scss/common.scss';
@import '../assets/scss/login.scss';
</style>
