<template>
  <div class="show_1">建设中</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Show_1",
  // 需要在子组件props中把父组件传递的属性先定义一遍，才能使用(最好不用大写)，props里面的数据都是父中传递的
  // 子组件data是子组件私有的数据，可读可写.
  // props中的数据只能读 
  // 参考https://blog.csdn.net/watson_pillow/article/details/109600259
  data: function () {
    return {
      pagecount: 0
    }
  },
  components: {
  },
  props: {
    prevHtml: String,
    nextHtml: String,
    page: Number,
    total: Number,
    pageSize: Number,
    maxPage: Number
  },
  methods: {
    // 父组件传递方法，使用v-on:的方式绑定（也可简写@）
    // 子组件通过$emit()出发父传递的方法
    // $emit（）方法的第一个参数为父组件绑定的名称，之后的参数为传递给函数的参数；
    // 子组件可以通过传参的方式向父组件传递数据；
    //pagehandler方法 跳转到page页
    pageHandler (page) {
      //here you can do custom state update
      var that = this
      console.log('page:', page)
      /*在子组件中由于渲染延时，需要加上一个延时，否则报错*/
      if (this.first_show == true) {
        console.log('首次加载页面无需执行滚动')
        that.page = page
        this.first_show = false
      }
      else {
        setTimeout(() => {
          document.getElementById("reset_page").scrollIntoView()
          // 参考京东淘宝，先滚动到指定位置，然后刷新数据，这样视觉体验较好
          setTimeout(() => {
            that.page = page
          }, 300)
        }, 50)
      }
    },
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.show_1 {
  font-size: 15px;
}
</style>
