/* eslint-disable */
import {
    v4 as uuidv4
} from 'uuid';
import plugins from '../js/plugin';

// 此方法为深度优先遍历，理解时只要举一个没有数组嵌套的例子；再举一个有两个元素的数组，其中有一个元素包含有深度为1的children数组。
async function create_classTable(that, options) {
    console.log('create_table')
    // obj实际上是数组
    function traverse(obj, index, temp_array, layer) {
        return new Promise(function (resolve) {
            if (obj[index].children) {
                // //console.log('递归:')
                let array = []
                for (let i = 0; i < obj[index].children.length; i++) {
                    traverse(obj[index].children, i, array, layer + 1) //chilrden对应的层级加1
                    if (i == obj[index].children.length - 1) {
                        // 当处理到children最后一个元素，也就意味着包含该children的节点需要在此一同被处理
                        let item = {}
                        // 此时这里obj[index].value和obj[index].label为包含该children的父元素信息
                        item.value = obj[index].value
                        item.label = obj[index].label
                        item.layer = layer
                        item.children = JSON.parse(JSON.stringify(array))
                        temp_array[index] = JSON.parse(JSON.stringify(item))
                        // 这里只有处理完children的最后一个元素同时也处理完父节点，才返回resolve，标志着包含children的此节点处理完毕
                        return resolve()
                    }
                }
            } else {
                let item = {}
                item.value = obj[index].value
                item.label = obj[index].label
                //console.log('layer:', layer)
                item.layer = layer //记录层级
                // //that.print('that.current_menu:', that.current_menu)
                if (that.current_menu.value === '团队管理') {
                    // 如果是岗位编辑，则增加此属性 ,另外，由于岗位没有children属性，所以必然出现在此else与语句里
                    console.log('团队管理')
                    item.auth_list = obj[index].auth_list
                }
                temp_array[index] = JSON.parse(JSON.stringify(item))
                return resolve()
            }
        })
    }
    let father_array = []
    for (let i = 0; i < options.length; i++) {
        let layer = 1
        // 当函数变量的值属于基本类型，这个“值”就是普通值，当变量的值是引用类型时，这个“值”时引用类型的地址。即数组共用
        await traverse(options, i, father_array, layer)
    }
    return Promise.resolve({
        array: father_array
    })
};

// 打开重命名框（一级类名或者子类名）
function rename_sub_class(that, row) {
    // //that.print('row:', row)
    that.class_edit.input_value = row.label
    that.class_edit.dialog_title = '重命名'
    that.class_edit.class_row = row
    that.class_edit.dialogFormVisible = true
};

// 打开添加框（一级类名或者子类名）
function add_sub_class(that, row, title) {
    that.class_edit.input_value = ''
    that.class_edit.dialogFormVisible = true
    that.class_edit.dialog_title = title
    that.class_edit.class_row = row
};

// 重命名保存(修改一级类名或者子类名)
async function save_rename_class(that, tableData, row, input_value) {
    // 判断
    if (that.class_edit.count_input_word > 12) {
        that.$message({
            showClose: true,
            message: '输入超过了12个字符',
            type: 'error'
        })
        return Promise.reject({})
    }
    // trim语句不放在watch中的原因是为了允许字符间存在空格，如果放在watch中实时删除空格的话就不排除此种情况
    that.class_edit.input_value = that.class_edit.input_value.trim()
    if (that.class_edit.input_value.length == 0) {
        that.$message({
            showClose: true,
            message: '未检测到有效输入',
            type: 'error'
        })
        return Promise.reject({})
    }

    function traverse(op_array) {
        return new Promise(function (resolve) {
            // 算法：只在匹配成功的情况下有返回，否则可能在没有匹配成功的情况下提前结束循环
            for (let i = 0; i < op_array.length; i++) {
                if (row.value == op_array[i].value) {
                    op_array[i].label = input_value
                    return resolve({})
                } else {
                    if (op_array[i].children) {
                        traverse(op_array[i].children).then(() => {
                            return resolve({})
                        })
                    } else {
                        // 见算法，这里不做处理，只要遍历数组，总归能匹配成功从而终止函数
                    }
                }
            }
        })
    }

    await traverse(tableData)
    return Promise.resolve({
        array: tableData
    })
};

//保存新增
async function save_add_class(that, tableData, row, operation_name, input_value) {
    //that.print("add_class:", row)
    // 判断
    if (that.class_edit.count_input_word > 12) {
        that.$message({
            showClose: true,
            message: '输入超过了12个字符',
            type: 'error'
        })
        return Promise.reject({})
    }
    if (operation_name == '添加一级类名' && tableData.length > 100) {
        that.$message({
            showClose: true,
            message: '同一层类别数量不能超过100',
            type: 'error'
        })
        return Promise.reject({})
    }
    if (operation_name == '添加岗位' && tableData.length >= 50) {
        that.$message({
            showClose: true,
            message: '最多可添加50个自定义岗位',
            type: 'error'
        })
        return Promise.reject({})
    }
    // trim语句不放在watch中的原因是为了允许字符间存在空格，如果放在watch中实时删除空格的话就不排除此种情况
    that.class_edit.input_value = that.class_edit.input_value.trim()
    if (that.class_edit.input_value.length == 0) {
        that.$message({
            showClose: true,
            message: '未检测到有效输入',
            type: 'error'
        })
        return Promise.reject({})
    }
    // 判断通过后开始处理
    function traverse(op_array, index, row, input_value) {
        return new Promise(function (resolve) {
            if (row.value == op_array[index].value) {
                if (op_array[index].children && op_array[index].children.length > 100) {
                    that.$message({
                        showClose: true,
                        message: '同一层类别数量不能超过100',
                        type: 'error'
                    })
                    return resolve()
                } else {
                    let item = {}
                    item.value = uuidv4()
                    item.label = input_value
                    item.layer = op_array[index].layer + 1
                    item.src = '' // 封面
                    item.from = '' // 封面来源
                    item.size = 0 // 封面大小
                    item.content = '' //文字说明
                    item.layout = 'left' //文字布局
                    if (!op_array[index].children) {
                        op_array[index].children = [] // 新建
                    }
                    op_array[index].children.push(JSON.parse(JSON.stringify(item)))
                    return resolve()
                }
            } else {
                if (op_array[index].children) {
                    //console.log('递归，遍历children')
                    // 这里的写法还不够好，应该使用promise.all来写，而不是在最后一个返回resolve()
                    for (let i = 0; i < op_array[index].children.length; i++) {
                        traverse(op_array[index].children, i, row, input_value)
                        if (i == op_array[index].children.length - 1) {
                            return resolve()
                        }
                    }
                } else {
                    return resolve()
                }
            }
        })
    }
    if (operation_name == '添加子类名') {
        //console.log('添加子类名')
        for (let i = 0; i < tableData.length; i++) {
            // 当函数变量的值属于基本类型，这个“值”就是普通值，当变量的值是引用类型时，这个“值”时引用类型的地址。即调函函数和被调用函数共用相同的数组
            await traverse(tableData, i, row, input_value)
        }
        for (let i = 0; i < tableData.length; i++) {
            //that.print("tableData:", tableData[i])
        }
        return Promise.resolve({
            array: tableData,
        })
    }
    if (operation_name == '添加一级类名') {
        //console.log('添加一级类名')
        let item = {}
        item.value = uuidv4()
        item.label = input_value
        item.layer = 1
        item.src = '' // 封面
        item.from = '' // 封面来源
        item.size = 0 // 封面大小
        item.content = '' //文字说明
        item.layout = 'left' //文字布局
        tableData.push(JSON.parse(JSON.stringify(item)))
        return Promise.resolve({
            array: tableData,
        })
    }
    if (operation_name == '添加岗位') {
        //console.log('添加岗位')
        let item = {}
        item.value = uuidv4()
        item.label = input_value
        item.layer = 1
        item.auth_list = [] // 授权清单
        tableData.push(JSON.parse(JSON.stringify(item)))
        return Promise.resolve({
            array: tableData,
        })
    }
};

// 保存输入
function confirm_input_change(that) {
    if (that.class_edit.dialog_title == '重命名') {
        // 重命名保存(修改一级类名或者子类名)
        // //console.log('save_rename_class')
        save_rename_class(that, that.class_edit.tableData, that.class_edit.class_row, that.class_edit.input_value).then((data) => {
            //console.log('then')
            //console.log(that.class_edit.tableData.length)
            // 使用es6的 Object.assign复制一个新的对象，重新渲染elment table
            that.class_edit.tableData = Object.assign([], data.array)
            that.class_edit.dialogFormVisible = false
        }).catch((err) => {
            //console.log('err:', err)
        })
    } else {
        // 添加一级类名或者子类名
        save_add_class(that, that.class_edit.tableData, that.class_edit.class_row, that.class_edit.dialog_title, that.class_edit.input_value).then((data) => {
            //console.log('then')
            //console.log(that.class_edit.tableData.length)
            // 使用es6的 Object.assign复制一个新的对象，重新渲染elment table
            that.class_edit.tableData = Object.assign([], data.array)
            that.class_edit.dialogFormVisible = false
        }).catch(() => {})
    }
};

//删除
async function delete_class(that, tableData, row) {
    that.class_edit.delete_row_value = row.value // 显示“按钮加载状态”，该功能提供防止重复点击功能

    that.print('query_before_delete_classes:', row)

    function traverse(op_array) {
        return new Promise(function (resolve) {
            // 算法：只在匹配成功的情况下有返回，否则可能在没有匹配成功的情况下提前结束循环
            for (let i = 0; i < op_array.length; i++) {
                if (row.value == op_array[i].value) {
                    // 只有第一层有src属性，其次存在正式文件夹图片，在删除元素之前必须先删除该图片，否则将成为垃圾数据且清理不了
                    if (op_array[i].layer == 1 && op_array[i].src.length > 0 && op_array[i].from != 'temp') {
                        // 删除正式文件夹图片
                        console.log('删除正式文件夹图片')
                        let file = op_array[i].src
                        let file_temp = file.split('?')[0] //截取参数前面的文件路径
                        let file_name = op_array[i].from + '/' + file_temp.substr(file_temp.lastIndexOf('/') + 1) //这里肯定是来自temp文件夹
                        plugins.oss_delete(that, file_name)
                        op_array.splice(i, 1)
                        return resolve()
                    } else {
                        // 直接删除图片
                        console.log('直接删除图片')
                        op_array.splice(i, 1)
                        return resolve()
                    }
                } else {
                    if (op_array[i].children) {
                        traverse(op_array[i].children).then(() => {
                            if (op_array[i].children.length == 0) {
                                //如果该children只有一个元素且被删除，俺么父元素的children长度为0，此时因该删除该children,防止table中出现代表存在children的箭头
                                delete op_array[i].children
                            }
                            return resolve({})
                        })
                    } else {
                        // 见算法，这里不做处理，只要遍历数组，总归能匹配成功从而终止函数
                    }
                }
            }
        })
    }

    let temp = that.$store.state.accept_data
    that.print('current_menu:', that.current_menu)
    let search_content = {}
    if (row.layer == 1) {
        search_content = {
            appletid: temp.AuthorizerAppid_id,
            menu_value: that.current_menu.value,
            subject: row.value,

        }
    } else if (row.layer == 2) {
        search_content = {
            appletid: temp.AuthorizerAppid_id,
            menu_value: that.current_menu.value,
            subject2: row.value,
        }
    } else {
        search_content = {
            appletid: temp.AuthorizerAppid_id,
            menu_value: that.current_menu.value,
            subject3: row.value,
        }
    }
    let content = {
        type: 'query_before_delete_classes',
        session: that.$store.state.session,
        search_content: search_content
    }
    let return_data = await plugins.bms_download(that, content)
    that.print('return_data:', return_data)

    that.class_edit.delete_row_value = '' // 关闭“按钮加载状态”，该功能提供防止重复点击功能

    if (return_data.num != 0) {
        that.$message({
            showClose: true,
            message: `该类别下有${return_data.num}项上传内容，无法删除`,
            type: 'error'
        })
    } else {
        await traverse(tableData)
        console.log('wating traverse execute')
        return Promise.resolve({
            array: tableData
        })
    }
};

//岗位删除操作
async function delete_sub_class_from_team_management(that, tableData, row) {
    that.class_edit.delete_row_value = row.value // 显示“按钮加载状态”，该功能提供防止重复点击功能
    console.log('delete_sub_class_from_team_management')
    // that.print('row:', row)

    let temp = that.$store.state.accept_data
    let content = {
        type: 'query_before_delete_position',
        session: that.$store.state.session,
        AuthorizerAppid: temp.AuthorizerAppid,
        position: that.class_edit.delete_row_value
    }
    let return_data = await plugins.bms_download(that, content)
    that.class_edit.delete_row_value = '' // 关闭“按钮加载状态”，该功能提供防止重复点击功能
    that.print('query result:', return_data)
    if (return_data.num != 0) {
        that.$message({
            showClose: true,
            message: `该岗位下有${return_data.num}个子账号，无法删除`,
            type: 'error'
        })
    } else {
        for (let i = 0; i < tableData.length; i++) { // tableData为地址引用
            if (tableData[i].value == row.value) {
                tableData.splice(i, 1)
                break
            }
        }
        that.$message({
            showClose: true,
            message: `删除成功`,
            type: 'success'
        })
    }
    return Promise.resolve({
        array: tableData
    })
};

//检查联级菜单参数el_cascader_value能否完全匹配。原因：当选择联级菜单后，如果此时再点击管理类名，删除或者修改了对应el_cascader_value
//的类名，会导致联级菜单置空，显示‘请选择’，但实际上el_cascader_value的值此时并不是空数组[],所以需要检查。如果检查匹配则不做任何处理；
//如果不匹配，则清空el_cascader_value。
function check_el_cascader_value(that) {
    //console.log('el_cascader_value:', that.class_edit.el_cascader_value)
    let exit_flag = false
    let first_children = []
    let second_children = []
    for (let i = 0; i < that.class_edit.el_cascader_value.length; i++) {
        if (i == 0) {
            let flag = false
            for (let j = 0; j < that.class_edit.tableData.length; j++) {
                if (that.class_edit.tableData[j].value == that.class_edit.el_cascader_value[i]) {
                    first_children = that.class_edit.tableData[j].children || [] //如果存在则赋值，不存在则赋[]
                    flag = true
                    break
                }
            }
            if (flag == false) {
                break
            } else {
                //匹配且是class_edit.el_cascader_value最后一个元素且没有子数组
                if (i == that.class_edit.el_cascader_value.length - 1 && first_children.length == 0) {
                    exit_flag = true
                }
            }
        }
        if (i == 1) {
            let flag = false
            for (let j = 0; j < first_children.length; j++) {
                if (first_children[j].value == that.class_edit.el_cascader_value[i]) {
                    second_children = first_children[j].children || [] //如果存在则赋值，不存在则赋[]
                    flag = true
                    break
                }
            }
            if (flag == false) {
                break
            } else {
                if (i == that.class_edit.el_cascader_value.length - 1 && second_children.length == 0) {
                    exit_flag = true
                }
            }
        }
        if (i == 2) {
            for (let j = 0; j < second_children.length; j++) {
                if (second_children[j].value == that.class_edit.el_cascader_value[i]) {
                    exit_flag = true
                    break
                }
            }
        }
    }
    if (exit_flag == false) {
        //console.log('exit_flag:', exit_flag)
        that.class_edit.el_cascader_value = []
    }
    return Promise.resolve({})
};

// 分类封面从temp文件夹移到正式文件夹
function classesImage_from_temp_to_formal(that, row) {
    return new Promise(function (resolve) {
        that.print('classesImage_from_temp_to_formal row:', row)
        console.log('需要转换的url:', that.article_eidt.temp.src)
        let temp = that.$store.state.accept_data

        let content = {}
        content.type = 'oss_classesImage_from_temp_to_formal'
        content.session = that.$store.state.session
        content.AuthorizerAppid_id = temp.AuthorizerAppid_id
        content.url_to_be_converted = that.article_eidt.temp.src // 需要从tenmp移到正式文件夹的图片地址
        content.url_to_be_deleted = row.src // 需要删除的原正式文件夹的图片地址
        let param = new URLSearchParams()
        param.append('username', 'dkeiYdjcnccnswwwoepsa')
        param.append('content', JSON.stringify(content))
        that.$ajax({
                url: '/zhitui/zuomian_oss',
                method: 'post',
                data: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                // console.log('oss_sign response:', response.data)
                if (response.status === 200 && typeof (response.data) === 'object') {
                    if (response.data.result && response.data.result == '登录过期') {
                        that.$message({
                            showClose: true,
                            message: '登录过期',
                            type: 'error'
                        })
                        that.$router.replace('/login')
                        return resolve()
                    }
                    return resolve(response.data)
                } else {
                    console.log('获取用户信息失败')
                    return reject({
                        result: 'error'
                    })
                }
            })
            .catch((response) => {
                console.log(response)
                return reject({
                    result: 'error'
                })
            })

    })
};

// 保存封面图文
async function save_classes_cover(that) {
    console.log('save_classes_cover')
    // 如果完整引入了 Element，那么 Vue.prototype 上会有一个全局方法 $loading，它的调用方式为：this.$loading(options)
    var loadingInstance = that.$loading({
        lock: true,
        text: '正在保存',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })

    let need_update_obj = {} //need_update_obj只是引用了地址

    function traverse(op_array) {
        return new Promise(function (resolve) {
            // 算法：只在匹配成功的情况下有返回，否则可能在没有匹配成功的情况下提前结束循环
            for (let i = 0; i < op_array.length; i++) {
                if (that.article_eidt.row_value == op_array[i].value) {
                    console.log('匹配i:', i)
                    need_update_obj = op_array[i] // 引用地址
                    return resolve({})
                } else {
                    if (op_array[i].children) {
                        traverse(op_array[i].children).then(() => {
                            return resolve({})
                        })
                    } else {
                        // 见算法，这里不做处理，只要遍历数组，总归能匹配成功从而终止函数
                    }
                }
            }
        })
    }

    await traverse(that.class_edit.tableData)
    that.print('need_update_obj:', need_update_obj)
    // 将temp文件夹里的图片移到正式文件夹，得到图片地址
    // 来自temp且非空才处理，如果是来自正式文件夹表示未变动则不需要处理
    let conversion_result = {}
    if (that.article_eidt.temp.src.length != 0 && that.article_eidt.temp.from == 'temp') {
        // 分两种情况。1.首次新建，原src为空；2.原src非空，替换原src。都放在服务器端处理
        conversion_result = await classesImage_from_temp_to_formal(that, need_update_obj)
        console.log('conversion_result:', conversion_result)
        need_update_obj.from = conversion_result.from
        need_update_obj.src = conversion_result.url
    }
    // 由于使用了await,必须执行完上面if花括号里的语句才能执行接下来的语句
    need_update_obj.content = that.article_eidt.temp.content
    need_update_obj.layout = that.article_eidt.temp.layout
    need_update_obj.size = that.article_eidt.temp.size
    that.print('need_update_obj to be changed:', need_update_obj)

    // 上面对need_update_obj的赋值其实更新的就是tableData数组

    // 更新class_edit.options
    if (that.class_edit.options[0].value == '全部') {
        //console.log('sava and quit1')
        // 更新菜单组件中的options
        that.class_edit.options = [{
                value: '全部',
                label: '全部'
            }],
            that.class_edit.options.push(...that.class_edit.tableData)
    } else {
        // 更新功能组件中的options
        that.class_edit.options = []
        that.class_edit.options.push(...that.class_edit.tableData)
    }

    //更新仓库accept_data
    let temp_accept_data = that.$store.state.accept_data
    temp_accept_data.bms_menu[that.menu_index].options = that.class_edit.tableData
    that.$store.commit('SET_accept_data', temp_accept_data)

    //更新服务器数据
    let content = {
        type: 'update_bms_menu',
        _id: temp_accept_data._id,
        bms_menu: temp_accept_data.bms_menu,
        session: that.$store.state.session
    }
    plugins.bms_update(that, content)
        .then(() => {
            setTimeout(() => {
                that.article_eidt.dialogFormVisible = false
                that.$message({
                    showClose: true,
                    message: '保存成功',
                    type: 'success'
                })
                loadingInstance.close()
            }, 500)
        })
        .catch(() => {
            that.article_eidt.dialogFormVisible = false
            //console.log('plugins.bms_update catch:', err)
        })
    return Promise.resolve({})
};

//保存退出编辑对话框
function save_and_quit(that) {
    return new Promise(function (resolve) {
        //console.log('sava and quit')
        if (that.class_edit.options[0].value == '全部') {
            //console.log('sava and quit1')
            // 更新菜单组件中的options
            that.class_edit.options = [{
                    value: '全部',
                    label: '全部'
                }],
                that.class_edit.options.push(...that.class_edit.tableData)
        } else {
            // 更新功能组件中的options
            that.class_edit.options = []
            that.class_edit.options.push(...that.class_edit.tableData)
        }
        //更新仓库accept_data
        let temp = that.$store.state.accept_data
        temp.bms_menu[that.menu_index].options = that.class_edit.tableData
        that.$store.commit('SET_accept_data', temp)
        //更新服务器数据
        let content = {
            type: 'update_bms_menu',
            phone: temp.phone,
            _id: temp._id,
            bms_menu: temp.bms_menu,
            session: that.$store.state.session
        }
        plugins.bms_update_no_loading(that, content)
            .then(() => {
                return resolve()
            })
            .catch((err) => {
                //console.log('plugins.bms_update catch:', err)
            })
        that.class_edit.isResouceShow = Math.random() //刷新页面参数
        check_el_cascader_value(that).then(() => {
            if (that.class_edit.options[0].value == '全部') {
                that.class_edit.el_cascader_value = ['全部']
            }
        })
    })
};

//打开权限设置对话框
function team_account_auth_set(that, row, title) {
    // that.print('team_account_auth_set row:', row)
    //赋值
    that.class_edit.auth.dialogAuthVisible = true
    that.class_edit.dialog_title = title
    that.class_edit.auth.positon_label = row.label
    that.class_edit.auth.position_value = row.value

    // 重置
    that.class_edit.auth.auth_array = [] // 所有可供选择的权限集合
    that.class_edit.auth.checkedAuth = [] // 已选中的权限集合

    // 读取主账号菜单信息
    let temp = that.$store.state.accept_data
    // //that.print('bms_menu:', temp.bms_menu)
    for (let i = 0; i < temp.bms_menu.length; i++) {
        // 去除下划线元素，重新创建一个数组
        if (temp.bms_menu[i].template.length != 0) {
            that.class_edit.auth.auth_array.push(temp.bms_menu[i])
        }
    }
    that.class_edit.auth.checkedAuth = JSON.parse(JSON.stringify(row.auth_list))
    that.class_edit.auth.checkAll = that.class_edit.auth.checkedAuth.length === that.class_edit.auth.auth_array.length
    return
};

// 确认权限更新
function confirm_auth_set(that) {
    for (let i = 0; i < that.class_edit.tableData.length; i++) {
        if (that.class_edit.tableData[i].value == that.class_edit.auth.position_value) {
            that.class_edit.tableData[i].auth_list = JSON.parse(JSON.stringify(that.class_edit.auth.checkedAuth))
        }
    }
    that.class_edit.auth.dialogAuthVisible = false
    return
};

// 入口函数
function class_edit_function(that, type, param) {
    // 待开发功能1：删除前查询，如果当前类别下存在数据，则无法删除
    // 待开发功能2: 命名时，在同一类别相同层级中不能重名
    // 待开发功能3: 重命名时，原类名下所有数据立即全部更名为新类名下数据,因为value值是唯一的，并不会变动     ////////////////////已开发完成
    // 待补充
    switch (type) {
        // 不同名称可以使用相同的模板，例如single_upload和batch_upload都匹配的是Tempupload模板，只是向模板传递的参数不一样
        case 'create_classTable':
            that.class_edit.tableData = JSON.parse(JSON.stringify(that.class_edit.options))
            if (param.menu && that.class_edit.options[0].value == '全部') {
                that.class_edit.tableData.splice(0, 1) //在菜单组件中编辑时，需要删除首元素{value: '全部',label: '全部'}
            }
            that.class_edit.dialogTableVisible = true
            console.log('that.class_edit.tableData:',that.class_edit.tableData)
            //除了create_classTable函数需要使用class_edit.options外，其他所有函数使用的都是tableData数组
            break;
        case 'rename_sub_class': //重命名
            rename_sub_class(that, param.row)
            break;
        case 'add_sub_class': //新增
            add_sub_class(that, param.row, param.title)
            break;
        case 'confirm_input_change': //保存输入
            confirm_input_change(that)
            break;
        case 'delete_sub_class': //删除
            delete_class(that, that.class_edit.tableData, param.row).then((data) => {
                    that.class_edit.tableData = Object.assign([], data.array)
                    save_and_quit(that)
                })
                .catch((err) => {
                    //console.log('plugins.bms_update catch:', err)
                })
            break;
        case 'delete_sub_class_from_team_management':
            delete_sub_class_from_team_management(that, that.class_edit.tableData, param.row).then((data) => {
                    that.class_edit.tableData = Object.assign([], data.array)
                })
                .catch((err) => {
                    //console.log('plugins.bms_update catch:', err)
                })
            break;
        case 'save_and_quit': //保存退出
            save_and_quit(that, param.menu).then(() => {
                //关闭窗口
                that.class_edit.dialogTableVisible = false
            }) //区别来自菜单组件还是功能组件
            break;
        case 'team_account_auth_set': //团队管理板块，为岗位设置权限
            team_account_auth_set(that, param.row, param.title)
            break;
        case 'confirm_auth_set': //团队管理板块,确认设置
            confirm_auth_set(that)
            break;
        case 'save_classes_cover':
            save_classes_cover(that)
            break;
        default:
    }
};

export default {
    class_edit_function
}; // 不加上报警