<template>
  <div class="formal">
    <div class="formal_left">
      <div
        style="width: 100%; height: 100%; padding-top: 30px; text-align: center"
      >
        <el-button
          size="medium"
          style="margin: auto; margin-top: 20px"
          type="primary"
          @click.stop="save_and_quit()"
          >保存</el-button
        >
        <div
          style="
            font-size: 13px;
            color: rgb(255, 255, 255);
            width: 100%;
            cursor: pointer;
            margin-top: 40px;
          "
          @click.stop="return_back()"
        >
          ← 返回列表
        </div>
      </div>
    </div>
    <div class="formal_right">
      <div class="formal_right_container">
        <div class="formal_right_1st">
          <!--顶部图片-->
          <img
            src="https://miya-1253902085.cos.ap-guangzhou.myqcloud.com/bms/formal_edit/phone_img.png"
            style="display: block; margin: 10px auto 5px"
          />
          <!--中间编辑区-->
          <div class="formal_edit_content">
            <!--封面编辑区-->
            <div
              style="
                width: 100%;
                height: 200px;
                width: 100%;
                position: relative;
                margin-top: 5px;
              "
            >
              <!--封面图片-->
              <div
                class="formal_bg_img"
                :style="{
                  backgroundImage:
                    'url(' +
                    'https://miya-1253902085.cos.ap-guangzhou.myqcloud.com/bms/formal_edit/bg.png' +
                    ')',
                  overflow: 'hidden',
                  margin: '0',
                }"
              >
                <img
                  v-show="
                    cut_dialog.graphic_introduction_cover.thumbnail_image_url
                      .length > 0
                  "
                  style="width: 100%; height: 100%"
                  :src="
                    cut_dialog.graphic_introduction_cover.thumbnail_image_url
                  "
                />
                <!--
                 <div
                    :style="{
                      zoom: 300 / pre_param.preview_data.w,
                      width: pre_param.preview_data.w + 'px',
                      height: pre_param.preview_data.h + 'px',
                    }"
                  >
                    <img
                      :src="pre_param.preview_data.url"
                      :style="pre_param.preview_data.img"
                    />
                  </div>
                  -->
              </div>
              <!--标题和时间展示-->
              <div class="formal_title_and_createtime_container">
                <input
                  placeholder="编辑标题"
                  maxlength="50"
                  class="formal_title"
                  v-model="work.name"
                  ref="input_title"
                  @focus="foucs_change_bg_color"
                  @blur="lose_focus_change_bg_color"
                />
                <div
                  style="
                    color: rgb(255, 255, 255);
                    font-size: 10px;
                    height: 33px;
                    line-height: 33px;
                    padding: 0px 20px;
                  "
                >
                  创建于{{ create_time() }}
                </div>
              </div>
              <!--裁剪按钮-->
              <div class="formal_cut_button" @click="cancel_cut()">
                <i
                  class="el-icon-circle-plus-outline"
                  style="margin-right: 3px"
                ></i
                >编辑封面
              </div>
            </div>
            <!--正文编辑区-->
            <div>
              <!--首段文字编辑-->
              <div
                style="
                  padding: 10px;
                  font-size: 12px;
                  overflow: hidden;
                  background-color: rgb(239, 239, 244);
                "
              >
                <div
                  style="
                    background: rgb(255, 255, 255);
                    width: 100%;
                    height: auto;
                    overflow: hidden;
                    position: relative;
                    border-radius: 5px;
                  "
                >
                  <!--keyup:按键修饰符 在按下回车键时调用，用于将回车符变为空字符，见：https://cn.vuejs.org/v2/guide/events.html#%E7%9B%91%E5%90%AC%E4%BA%8B%E4%BB%B6-->
                  <textarea
                    placeholder="添加文字（题记）"
                    ref="textarea"
                    maxlength="3000"
                    class="formal_first_paragrah"
                    :style="{ height: work.describe_height }"
                    v-model="work.describe"
                  >
                  </textarea>
                </div>
              </div>
              <!--占位图-->
              <template v-if="upload_array.length == 0">
                <div class="formal_hold_position">
                  <div
                    class="formal_btn_work_edit_text"
                    @click="article_edit_dialog_control()"
                  >
                    <i class="iconfont iconwenzi"></i>
                    <div class="formal_tips_wrap">添加文字</div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-for="(item, index) in upload_array" v-bind:key="index">
                  <div style="position: relative">
                    <img
                      :src="item.url.thumbnail_image_url"
                      style="width: 100%; min-height: 120px"
                    />
                    <div
                      class="formal_btn_work_edit_text"
                      @click="article_edit_dialog_control(index)"
                    >
                      <i class="iconfont iconwenzi"></i>
                      <div class="formal_tips_wrap" v-if="index == 0">
                        添加文字
                      </div>
                    </div>
                  </div>
                  <div
                    v-show="item.content"
                    class="formal_article_text"
                    :style="{ textAlign: item.layout }"
                  >
                    {{ item.content }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!--底部预览按钮-->
          <div class="formal_bottom_preview_button" @click.stop="preview()">
            <span>
              <i class="el-icon-caret-right" style="font-size: 25px"></i>
            </span>
            <span>点击预览</span>
          </div>
        </div>
        <div class="formal_right_2nd">
          <div
            class="formal_image_list_button"
            :class="{
              formal_button_active: active_button == 'image',
            }"
            @click="click_button('image')"
          >
            <i class="el-icon-menu"></i>
          </div>
          <div
            class="formal_set_button"
            :class="{
              formal_button_active: active_button == 'set',
            }"
            @click="click_button('set')"
          >
            <i class="el-icon-setting"></i>
          </div>
        </div>
        <div class="formal_right_3rd">
          <transition name="el-fade-in-linear">
            <div
              v-show="active_button == 'image'"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
            >
              <div class="formal_right_3rd_top_containe">
                <div style="display: inline-block; margin: 14px 0px 14px 14px">
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-plus"
                    @click.stop="submitUpload()"
                    >添加图片 {{ upload_array.length }}/{{
                      max_upload_num
                    }}</el-button
                  >
                  <el-button
                    size="small"
                    @click.stop="sort_click()"
                    v-if="upload_array.length > 0"
                  >
                    排序
                  </el-button>
                  <div
                    style="
                      width: 0px;
                      height: 0px;
                      opacity: 0;
                      overflow: hidden;
                    "
                  >
                    <input
                      ref="upload"
                      @change="file_change($event)"
                      name="upload"
                      type="file"
                      accept="image/*"
                      multiple
                    />
                  </div>
                </div>
              </div>
              <div class="formal_right_3rd_bottom_container">
                <el-empty
                  v-if="upload_array.length == 0"
                  description="暂时还没有任何图片哦！"
                  :image-size="100"
                  style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    font-size: 14px;
                  "
                ></el-empty>
                <div
                  v-for="(item, index) in upload_array"
                  v-bind:key="index"
                  class="formal_upload_list_item"
                  :style="transform_coumputed(index)"
                >
                  <div
                    class="formal_upload_list_item_img"
                    :style="{
                      backgroundImage:
                        'url(' + item.url.thumbnail_image_url + ')',
                    }"
                  ></div>
                  <div class="formal_upload_list_item_del_btn">
                    <el-popconfirm
                      title="确定要删除这张图片吗？"
                      @confirm="delete_img(index)"
                    >
                      <div style="width: 100%" slot="reference">
                        <i class="el-icon-delete"></i>
                      </div>
                    </el-popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="el-fade-in-linear">
            <div
              v-show="active_button == 'set'"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
            >
              <div
                style="
                  width: 100%;
                  height: 60px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-button disabled type="primary" size="small"
                  >选项设置</el-button
                >
              </div>
              <div style="padding: 0px 40px">
                <!--公开-->
                <div
                  style="
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 0px 0px 15px;
                  "
                >
                  <div
                    style="
                      overflow: hidden;
                      padding: 15px 0px 0px;
                      line-height: 23px;
                    "
                  >
                    <div style="overflow: hidden">
                      <div
                        style="
                          font-size: 13px;
                          color: rgb(0, 0, 0);
                          float: left;
                        "
                      >
                        是否公开：{{ work.public }}
                      </div>
                      <div style="float: right">
                        <el-switch
                          v-model="work.public"
                          active-color="#409EFF"
                          inactive-color="#DCDFE6"
                          active-value="公开"
                          inactive-value="不公开"
                          :width="42"
                        >
                        </el-switch>
                      </div>
                    </div>
                    <div
                      style="font-size: 12px; color: rgba(150, 150, 150, 0.8)"
                    >
                      {{
                        work.public == '公开'
                          ? '所有人可见，并加入到您的主页中（推荐）'
                          : '仍可以分享给客户或朋友，但不会出现在您的主页中。'
                      }}
                    </div>
                  </div>
                </div>
                <!--第二格选项-->
                <div
                  style="
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 0px 0px 15px;
                  "
                >
                  <!--第二格选项：分类-->
                  <div
                    style="
                      overflow: hidden;
                      padding: 15px 0px 0px;
                      line-height: 23px;
                    "
                  >
                    <div
                      style="font-size: 13px; color: rgb(0, 0, 0); float: left"
                    >
                      分类
                    </div>
                    <div class="holdplace_style_control" style="float: right">
                      <el-tooltip
                        effect="light"
                        content="请在列表页面编辑分类"
                        placement="top-start"
                      >
                        <span
                          style="
                            margin-right: 5px;
                            cursor: pointer;
                            color: rgba(150, 150, 150, 0.8);
                          "
                          ><i class="el-icon-question"></i>帮助</span
                        >
                      </el-tooltip>
                      <el-cascader
                        :key="class_edit.isResouceShow"
                        v-model="class_edit.el_cascader_value"
                        :options="class_edit.options"
                        size="mini"
                      ></el-cascader>
                    </div>
                  </div>
                  <!--第二格选项：允许下载图片-->
                  <div
                    style="
                      overflow: hidden;
                      padding: 15px 0px 0px;
                      line-height: 23px;
                    "
                  >
                    <div
                      style="font-size: 13px; color: rgb(0, 0, 0); float: left"
                    >
                      允许下载图片：否
                    </div>
                    <div style="float: right">
                      <el-switch disabled :width="42"> </el-switch>
                    </div>
                  </div>
                  <!--第二格选项：图片宽度-->
                  <div
                    style="
                      overflow: hidden;
                      padding: 15px 0px 0px;
                      line-height: 23px;
                    "
                  >
                    <div style="overflow: hidden">
                      <div
                        style="
                          font-size: 13px;
                          color: rgb(0, 0, 0);
                          float: left;
                        "
                      >
                        图片宽度
                      </div>
                      <div
                        style="float: right; color: #409eff; cursor: pointer"
                      >
                        <el-dropdown @command="width_ratio_handleCommand">
                          <span style="color: #409eff; font-size: 13px">
                            <i
                              class="el-icon-edit"
                              style="margin-right: 5px"
                            />{{ work.width }}
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="50%"
                              >50%</el-dropdown-item
                            >
                            <el-dropdown-item command="60%"
                              >60%</el-dropdown-item
                            >
                            <el-dropdown-item command="70%"
                              >70%</el-dropdown-item
                            >
                            <el-dropdown-item command="80%"
                              >80%</el-dropdown-item
                            >
                            <el-dropdown-item command="90%"
                              >90%</el-dropdown-item
                            >
                            <el-dropdown-item command="100%"
                              >100%</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>
                    <div
                      style="font-size: 12px; color: rgba(150, 150, 150, 0.8)"
                    >
                      图片宽度占行宽的比率，控制图片显示的大小
                    </div>
                  </div>
                </div>
                <!--第三格选项-->
                <div style="padding: 0px 0px 15px">
                  <!--第三格选项：显示创建时间-->
                  <div
                    style="
                      overflow: hidden;
                      padding: 15px 0px 0px;
                      line-height: 23px;
                    "
                  >
                    <div
                      style="font-size: 13px; color: rgb(0, 0, 0); float: left"
                    >
                      显示创建时间：{{ work.show_create_time }}
                    </div>
                    <div style="float: right">
                      <el-switch
                        v-model="work.show_create_time"
                        active-color="#409EFF"
                        inactive-color="#DCDFE6"
                        active-value="是"
                        inactive-value="否"
                        :width="42"
                        @change="confrim_show_create_time"
                      >
                      </el-switch>
                    </div>
                  </div>
                  <!--第三格选项：显示点赞和浏览量-->
                  <div
                    style="
                      overflow: hidden;
                      padding: 15px 0px 0px;
                      line-height: 23px;
                    "
                  >
                    <div
                      style="font-size: 13px; color: rgb(0, 0, 0); float: left"
                    >
                      显示点赞/浏览量：{{ work.show_view_and_like }}
                    </div>
                    <div style="float: right">
                      <el-switch
                        v-model="work.show_view_and_like"
                        active-color="#409EFF"
                        inactive-color="#DCDFE6"
                        active-value="是"
                        inactive-value="否"
                        :width="42"
                        @change="confrim_show_view_and_like"
                      >
                      </el-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!--正文文字编辑-->
    <el-dialog
      :visible.sync="article_eidt.dialogFormVisible"
      width="300px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :destroy-on-close="true"
    >
      <div slot="title"></div>
      <div
        class="formal_article_edit_img"
        :style="{
          backgroundImage: 'url(' + article_eidt.temp.src + ')',
        }"
      >
        <div
          class="formal_article_edit_img_describe"
          @click.stop="
            add_article_image_describe(article_eidt.temp.img_describe)
          "
        >
          <i class="el-icon-circle-plus-outline" style="margin-right: 3px"></i
          >添加图片描述
        </div>
      </div>
      <div
        v-if="article_eidt.temp.img_describe"
        style="
          text-align: center;
          font-size: 12px;
          color: #8b8383;
          padding: 5px;
        "
      >
        {{ article_eidt.temp.img_describe }}
      </div>
      <div class="formal_article_layout">
        <a
          :style="{
            color: article_eidt.temp.layout == 'left' ? '#409EFF' : '',
          }"
          @click.stop="article_eidt.temp.layout = 'left'"
        >
          <i class="iconfont iconjuzuo" style="margin-right: 5px"></i>
        </a>
        <a
          :style="{
            color: article_eidt.temp.layout == 'center' ? '#409EFF' : '',
          }"
          @click.stop="article_eidt.temp.layout = 'center'"
        >
          <i class="iconfont iconjuzhong" style="margin-right: 5px"></i>
        </a>
        <el-tooltip
          class="item"
          content="文字两端对齐"
          placement="top-start"
          effect="light"
        >
          <a
            :style="{
              color: article_eidt.temp.layout == 'justify' ? '#409EFF' : '',
            }"
            @click.stop="article_eidt.temp.layout = 'justify'"
          >
            <i class="iconfont iconhengpai"></i>
          </a>
        </el-tooltip>

        <a style="float: right" @click.stop="article_eidt.temp.content = ''">
          <i class="el-icon-delete" style="font-size: 18px"></i>
        </a>
      </div>
      <textarea
        placeholder="请输入正文"
        class="formal_article_textarea"
        v-model="article_eidt.temp.content"
        ref="article_edit_textarea"
        :style="{
          textAlign: article_eidt.temp.layout,
          height: article_eidt.temp.height,
        }"
      ></textarea>
      <div style="display: flex; justify-content: center">
        <el-button
          size="medium"
          style="width: 49%"
          @click.stop="article_edit_save()"
          >保存</el-button
        >
        <div style="flex: 1"></div>
        <el-button
          size="medium"
          style="width: 49%"
          @click.stop="article_edit_dialog_control()"
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!--裁剪封面对话框-->
    <el-dialog
      title="封面裁剪"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="cut_dialog.dialogTableVisible"
      :before-close="cancel_cut"
      width="960px"
    >
      <div class="formal_tips">
        <i class="el-icon-info"></i
        ><span style="margin-left: 2px; color: rgb(146 139 139)"
          >小贴士：
          <i class="el-icon-check" style="margin-right: 3px"></i
          >滚动鼠标滑轮可以缩放图片，
          <i class="el-icon-check" style="margin-right: 3px"></i
          >裁剪区可以移动图片，
          <i class="el-icon-check" style="margin-right: 3px"></i
          >建议宽高比为3:2。</span
        >
      </div>
      <div class="formal_dialog">
        <div
          class="formal_vueCropper"
          ref="cover_img_container"
          :style="{
            width: cut_dialog.img_container.width + 'px',
            height: cut_dialog.img_container.height + 'px',
          }"
        >
          <!--下面Img绑定style是用于编辑进入时初始化数据-->
          <img
            class="formal_vueCropper_img"
            v-show="
              cut_dialog.temp_graphic_introduction_cover.original_image_url
            "
            :src="cut_dialog.temp_graphic_introduction_cover.original_image_url"
            ref="cover_img"
            v-drag="{
              width: cut_dialog.img_container.width,
              height: cut_dialog.img_container.height,
            }"
            :style="{
              left: cut_dialog.graphic_introduction_cover.style.left + 'px',
              top: cut_dialog.graphic_introduction_cover.style.top + 'px',
              width: cut_dialog.graphic_introduction_cover.style.width + 'px',
              height: cut_dialog.graphic_introduction_cover.style.height + 'px',
            }"
          />
          <div class="formal_cut_tips_border formal_cut_tips_border1"></div>
          <div class="formal_cut_tips_border formal_cut_tips_border2"></div>
          <div class="formal_cut_tips_border formal_cut_tips_border3"></div>
          <div class="formal_cut_tips_border formal_cut_tips_border4"></div>
        </div>
        <div class="formal_dialog_button_container">
          <el-button
            size="small"
            type="success"
            @click.stop="submitSingleUpload()"
            >选择文件</el-button
          >
          <el-button
            size="small"
            type="primary"
            style="margin-top: 20px; margin-left: 0"
            @click.stop="cover_image_processing('cover_image_save')"
            >保存</el-button
          >
          <el-button
            size="small"
            style="margin-top: 20px; margin-left: 0"
            @click.stop="cancel_cut()"
            >取消</el-button
          >
          <div style="width: 0px; height: 0px; opacity: 0; overflow: hidden">
            <input
              ref="upload_single"
              @change="file_single_change($event)"
              name="upload"
              type="file"
              accept="image/*"
            />
          </div>
        </div>
      </div>
    </el-dialog>

    <!--排序-->
    <el-dialog
      title="排序"
      :visible.sync="sort.dialogFormVisible"
      width="1100px"
      :close-on-click-modal="false"
      :before-close="sort_confirm_close"
    >
      <div
        style="
          min-height: 390px;
          cursor: move;
          padding-top: 20px;
          overflow: hidden;
        "
      >
        <!--使用draggable组件-->
        <draggable
          v-model="sort.temp_array"
          chosenClass="draggable_chosen"
          forceFallback="true"
          animation="200"
        >
          <transition-group>
            <div
              v-for="item in sort.temp_array"
              v-bind:key="item.url.url"
              class="formal_sort_item"
            >
              <div
                class="formal_upload_list_item_img"
                :style="{
                  backgroundImage: 'url(' + item.url.thumbnail_image_url + ')',
                }"
              ></div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click.stop="sort_click()">取消</el-button>
        <el-button type="primary" size="small" @click.stop="save_sort()"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <!--上传进度显示-->
    <el-dialog
      :visible.sync="dialogFormVisible"
      width="900px"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <div class="upload_title">{{ upload_message() }}</div>
        <el-popover placement="bottom" width="390" v-model="problem_visible">
          <div class="problem_content">
            <p style="font-size: 14px; margin-bottom: 10px">
              <i
                class="el-icon-info"
                style="color: #e3912b; font-size: 20px"
              ></i>
              上传遇到问题?
            </p>
            <p style="margin-bottom: 15px">请检查以下情况后，刷新页面重试：</p>
            <p style="color: #8d8a8a">
              <span style="margin-right: 5px"
                ><i class="el-icon-check"></i></span
              >检查网络环境是否稳定
            </p>
            <p style="color: #8d8a8a">
              <span style="margin-right: 5px"
                ><i class="el-icon-check"></i></span
              >检查VPN，并确定关闭
            </p>
            <p style="color: #8d8a8a">
              <span style="margin-right: 5px"
                ><i class="el-icon-check"></i></span
              >请尝试在电脑-网络中，把dns改成223.5.5.5 或者 223.6.6.6 后重试
            </p>
            <div style="text-align: right; margin: 0">
              <button
                @click="problem_visible = false"
                type="button"
                class="ant-button blue-button"
                style="font-size: 12px; margin-top: 20px"
              >
                <span>知道了</span>
              </button>
            </div>
          </div>

          <div slot="reference" class="problem">
            <i class="el-icon-question" style="color: #e3912b"></i>
            帮助
          </div>
        </el-popover>
      </div>
      <div class="upload_progress_file">
        <ul>
          <li
            v-for="(item, index) in temp_array"
            v-bind:key="index"
            class="upload_progress_file_item"
            :class="{
              upload_progress_file_animate: index == upload_active_index,
            }"
          >
            <span class="upload_progress_item_filename" :title="item.name">{{
              item.name
            }}</span>
            <span
              class="upload_progress_item_filesize"
              :title="upload_size(index)"
              >{{ upload_size(index) }}</span
            >
            <span
              class="upload_progress_item_status"
              :style="{ color: item.result == 'fail' ? '#f44336' : '' }"
              :title="upload_status(index)"
            >
              <i
                class="el-icon-circle-check"
                style="color: #5bda92"
                v-if="item.result == 'ok'"
              ></i
              >{{ upload_status(index) }}</span
            >
          </li>
        </ul>
      </div>
    </el-dialog>

    <!--预览抽屉-->
    <el-drawer
      title=""
      :visible.sync="drawer.drawer"
      :with-header="false"
      direction="ttb"
      size="100%"
      :modal="false"
    >
      <div class="article_container">
        <!--标题 创建时间  题记-->
        <div class="workDetailsContent">
          <div class="workTile">{{ work.name }}</div>
          <div class="workCreatedAt" v-show="work.show_create_time == '是'">
            {{ work.edit_time_format }}
          </div>
          <div class="workContent">{{ work.describe }}</div>
          <div class="work_details_play_btn">
            <el-button
              type="primary"
              size="small"
              @click.stop="drawer.drawer = false"
              >退出预览</el-button
            >
          </div>
        </div>
        <!--正文-->
        <div
          class="work_details_image_wrap"
          v-for="(item, index) in upload_array"
          v-bind:key="index"
        >
          <div
            style="
              position: absolute;
              z-index: 1000000;
              width: 100%;
              height: 100%;
            "
          ></div>
          <div>
            <el-image
              :src="item.url.url"
              :style="{ width: final_width(index), margin: '0 auto' }"
            >
              <div slot="placeholder" style="width: 100%; min-height: 500px">
                <img :src="bg_src" style="width: 100%; height: auto" /></div
            ></el-image>
            <pre
              v-show="item.img_describe"
              class="work_img_name"
            ><span>{{item.img_describe}}</span>
            </pre>
          </div>
          <pre
            class="work_img_alignment"
            :style="{ textAlign: item.layout }"
            v-show="item.content.length > 0"
            >{{ item.content }}</pre
          >
        </div>
      </div>
    </el-drawer>

    <!--预览抽屉:成功保存退出前的查看-->
    <el-drawer
      title=""
      :visible.sync="drawer.before_quit_drawer"
      :with-header="false"
      direction="ttb"
      size="100%"
      :modal="false"
    >
      <div class="article_container">
        <div class="workDetailsContent">
          <div class="workTile">{{ work.name }}</div>
          <div class="workCreatedAt" v-show="work.show_create_time == '是'">
            {{ work.edit_time_format }}
          </div>
          <div class="workContent">{{ work.describe }}</div>
          <div class="work_details_play_btn">
            <el-button
              size="small"
              @click.stop="return_last_layer()"
              type="primary"
              >返回到列表页</el-button
            >
          </div>
        </div>
        <div
          class="work_details_image_wrap"
          v-for="(item, index) in drawer.preview_return_data
            .graphic_introduction"
          v-bind:key="index"
        >
          <div
            style="
              position: absolute;
              z-index: 1000000;
              width: 100%;
              height: 100%;
            "
          ></div>
          <div>
            <el-image
              :src="item.path"
              :style="{ width: final_width(index), margin: '0 auto' }"
            >
              <div slot="placeholder" style="width: 100%; min-height: 500px">
                <img :src="bg_src" style="width: 100%; height: auto" /></div
            ></el-image>
            <pre
              v-show="item.img_describe"
              class="work_img_name"
            ><span>{{item.img_describe}}</span>
            </pre>
          </div>
          <pre
            class="work_img_alignment"
            :style="{ textAlign: item.layout }"
            v-show="item.content.length > 0"
            >{{ item.content }}</pre
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import plugins from '../assets/js/plugin';
import calcTextareaHeight from '../assets/js/calcTextareaHeight';
import cover_image_processing from '../assets/js/cover_image_processing';
//导入draggable组件
import draggable from 'vuedraggable';
export default {
  name: '',
  props: {
  },
  data () {
    return {
      active_button: 'image',

      bg_src: 'https://miya-1253902085.cos.ap-guangzhou.myqcloud.com/bms/formal_edit/bg.png', //背景图地址

      //页面传参
      work: {
        _id: null,
        AuthorizerAppid_id: '',
        name: '',  // 记录名称 标题
        creation_time: null,
        edit_time: null,
        edit_time_format: '',
        describe: '',  // 题记,首段文字
        describe_height: '38px',
        public: '公开',
        menu_value: '',   // 所属目录名称，搜索时用于区分
        width: '100%',
        old_show_create_time: '是', // 记录初始值，用于判断show_create_time是否作修改从而更新服务器数据，无论是新建还是编辑，都需要从列表页接受原始数据
        // 当新建的时候进入
        show_create_time: '是',
        old_show_view_and_like: '否',
        show_view_and_like: '否',
        subject: '',
        subject2: '',
        subject3: '',
        graphic_introduction_cover: {},
        graphic_introduction: [],
        // 20211201 bug修复，textarea初次打开时不明原因高度不够，导致底部一行文字无法全部显现，这里增加一个初次打开参数进行控制
        first_show: true
      },

      //分类编辑控制参数
      class_edit: {
        dialogTableVisible: false,
        el_cascader_value: [],
        options: [],
        tableData: [],
        input_value: '',
        count_input_word: 0,
        class_row: {},
        dialog_title: '标题',
        dialogFormVisible: false,
        isResouceShow: 0
      },

      //正文文字编辑
      article_eidt: {
        dialogFormVisible: false,
        index: -1,
        temp: {
          src: '',
          layout: 'left',
          content: '',
          height: '78px',
          img_describe: '',
        },
      },

      // 预览抽屉
      drawer: {
        drawer: false,
        before_quit_drawer: false,
        preview_return_data: []
      },

      // 裁剪封面对话框
      cut_dialog: {
        dialogTableVisible: false,
        img_container: {   // 裁剪框大小
          width: 750,
          height: 500
        },
        graphic_introduction_cover: {       //最终提交到服务器的数据，编辑状态进入页面时需要接受服务器数据以初始化
          path: '',        //裁剪大图，直接显示
          from: '',
          type: '',  // 裁剪参数
          percent: 0,
          size: 0,
          name: '',
          thumbnail_image_url: '',  // 300裁剪缩略图，直接显示
          original_image_url: '',   // 980原图
          style: {    //裁剪信息
            width: '',
            height: '',
            left: 0,
            top: 0
          }
        },
        temp_graphic_introduction_cover: {  //裁剪框临时数据，打开裁剪框时接受graphic_introduction_cover的数据以初始化
          path: '',        //裁剪大图，直接显示
          from: '',
          type: '',  // 裁剪参数
          percent: 0,
          size: 0,
          name: '',
          thumbnail_image_url: '',  // 300裁剪缩略图，直接显示
          original_image_url: '',   // 980原图
          style: {    //裁剪信息
            width: '',
            height: '',
            left: 0,
            top: 0
          }
        }
      },

      // 排序
      sort: {
        dialogFormVisible: false,
        temp_array: [],// 排序临时数组
      },

      // batch_upload参数
      dialogFormVisible: false,
      temp_array: [],
      upload_array: [],
      upload_result: {   // 数量统计
        flag: 0,
        success: 0
      },
      upload_active_index: -1,
      problem_visible: false,
      max_upload_num: 50, // 最大上传数量
      is_save: false, //当销毁组件时，如果此时未点击保存，则删除临时图片
    }
  },
  components: {
    draggable
  },
  created () {
    // 如果直接在新的标签页的地址栏中打开本页面由于session已经重置，将跳转到登陆页面
    if (!this.$store.state.session || !this.$store.state.accept_data) {
      this.$router.replace('/login')
      return
    }
  },
  mounted () {
    this.work.menu_value = this.$route.query.menu_value
    this.class_edit.options.push(...this.$route.query.options)
    if (!this.$route.query.work) {
      console.log('新建')
      this.work.edit_time = this.work.creation_time = new Date()
      this.work.edit_time_format = plugins.formatTime_ymd(this.work.edit_time)
      // 记录初始值，用于判断show_create_time是否作修改从而更新服务器数据，无论是新建还是编辑，都需要从列表页接受原始数据
      // 非null值表示已创建过内容，需要继承其设置
      if (this.$route.query.show_create_time != null) {
        console.log('非null')
        this.work.old_show_create_time = this.$route.query.show_create_time
        this.work.show_create_time = this.$route.query.show_create_time
        this.work.old_show_view_and_like = this.$route.query.show_view_and_like
        this.work.show_view_and_like = this.$route.query.show_view_and_like
      }
    } else {
      console.log('编辑')
      this.initialize(this.$route.query.work)
    }
  },
  destroyed () {
    // 清除所有temp图片
    console.log('destroyed')
  },
  // 自定义指令：focus,使用方式：v-focus,见：https://cn.vuejs.org/v2/guide/custom-directive.html
  directives: {
    drag (el, binding, vnode) {
      var that = vnode.context   // // VUE自定义指令获取当前this:函数里面第三个参数vnode 它的vnode.context就是当前的vm实例
      let oDiv = el // 当前元素
      var cover_img = null
      var cover_img_container = null
      // 用于移动和缩放后的偏差修正
      function check () {
        cover_img = that.$refs.cover_img.getBoundingClientRect()
        cover_img_container = that.$refs.cover_img_container.getBoundingClientRect()
        let max_xdistance = cover_img_container.left - cover_img.left
        let max_xdistance_right = cover_img.right - cover_img_container.right
        let max_ydistance = cover_img_container.top - cover_img.top
        let max_ydistance_bottom = cover_img.bottom - cover_img_container.bottom
        if (max_xdistance < 0) {  //表示图片左边距大于父元素左边距，即移过了头，需要修正。
          oDiv.style.left = '0px'
        }
        if (max_xdistance_right < 0) {  //值修正
          // 需要注意的是left是相当于父元素左边距的距离，而非视窗
          oDiv.style.left = cover_img_container.width - cover_img.width + 'px'
        }
        if (max_ydistance < 0) {
          oDiv.style.top = 0 + 'px'
        }
        if (max_ydistance_bottom < 0) {
          oDiv.style.top = cover_img_container.height - cover_img.height + 'px'
        }
      }
      oDiv.onmousedown = function (e) {
        cover_img = that.$refs.cover_img.getBoundingClientRect()
        cover_img_container = that.$refs.cover_img_container.getBoundingClientRect()
        // console.log('mousedown e:', e)
        // 记录移动之前的参数
        let disX = e.clientX - oDiv.offsetLeft    // e.clientX:鼠标当前x坐标。oDiv.offsetLeft：图片相对于父元素的左偏移量
        let disY = e.clientY - oDiv.offsetTop
        oDiv.onmousemove = function (e) {
          //用鼠标当前坐标减去鼠标之前的坐标得到移动距离，然后加上元素之前相对于父元素的偏移量,得到元素即将移动到的相对于父元素的新偏移量
          let l = e.clientX - disX
          let t = e.clientY - disY
          //允许的最大向右偏移量
          let max_xdistance = cover_img_container.left - cover_img.left
          //允许的最大向左偏移量
          let max_xdistance_right = cover_img.right - cover_img_container.right
          // 允许的最大向下偏移量
          let max_ydistance = cover_img_container.top - cover_img.top
          // 允许的最大向上偏移量
          let max_ydistance_bottom = cover_img.bottom - cover_img_container.bottom
          if (l <= max_xdistance && l <= max_xdistance_right && l >= (cover_img_container.width - cover_img.width)) {
            oDiv.style.left = l + 'px'
          }
          if (t <= max_ydistance && t <= max_ydistance_bottom && t >= (cover_img_container.height - cover_img.height)) {
            oDiv.style.top = t + 'px'
          }
          setTimeout(() => {   // 也可以不用延时
            check()
          }, 0)
        }
        // 鼠标松开时，拖拽结束
        document.onmouseup = function () {
          oDiv.onmousemove = null
          oDiv.onmouseup = null
        }
        // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
        return false
      }
      oDiv.onmousewheel = function (event) {    //滚轮放大缩小
        cover_img = that.$refs.cover_img.getBoundingClientRect()
        cover_img_container = that.$refs.cover_img_container.getBoundingClientRect()
        var wheelDelta = event.wheelDelta ? event.wheelDelta : (event.deltalY * (-40));  //获取当前鼠标的滚动情况
        if (wheelDelta > 0) { // 放大时，每次放大到原来的1.1倍
          oDiv.style.width = cover_img.width * 1.02 + 'px' //注意，我的缩放是以左上角位置为中心进行缩放的，如果要以图片中心为缩放点，需要修改 imgX，imgY的值
          oDiv.style.height = cover_img.height * 1.02 + 'px'
        } else {
          // console.log('binding:', binding.value)
          // 最小缩放尺寸，注意需要宽高同时缩小放大，不能仅对一边缩放
          if (cover_img.width * 0.98 >= binding.value.width && cover_img.height * 0.98 >= binding.value.height) {
            oDiv.style.width = cover_img.width * 0.98 + 'px'
            oDiv.style.height = cover_img.height * 0.98 + 'px'
          }
          else {
            // 在无法按0.98倍数缩小的情况下，判断最小尺寸下展示的条件是否成立
            // 分两种情况，只要满足一种即可，如果都不满足，则不做任何操作。备注：优先按宽度缩小到最小宽度处理。
            if (cover_img.height * (binding.value.width / cover_img.width) >= binding.value.height) {
              // 高度也满足同比例缩小要求
              oDiv.style.height = cover_img.height * (binding.value.width / cover_img.width) + 'px'
              oDiv.style.width = binding.value.width + 'px'
            }
            else {
              if (cover_img.width * (binding.value.height / cover_img.height) >= binding.value.width) {
                oDiv.style.width = cover_img.width * (binding.value.height / cover_img.height) + 'px'
                oDiv.style.height = binding.value.height + 'px'
              }
            }
          }
        }
        check()
        /*
       setTimeout(() => {   // 也可以不用延时
         check()
       }, 10)
       */
        event.preventDefault && event.preventDefault() // 阻止默认事件，可能在滚动的时候，浏览器窗口也会滚动
        return false
      }
    }
  },

  watch: {
    'article_eidt.temp.content' () {  // 对象具体属性的watch可以直接用引号把属性括起来，就可以实现对象中特定属性的监听事件：
      let that = this
      // 这里有必要使用延时，不然可能由于渲染不及时报错：Failed to execute 'getComputedStyle' on 'Window': parameter 1 is not of type 'Element'
      setTimeout(() => {
        that.article_eidt.temp.height = calcTextareaHeight(that.$refs.article_edit_textarea).height
        console.log('that.article_eidt.temp.height :', that.article_eidt.temp.height)
      }, 50)
    },
    'work.describe' () {  // 对象具体属性的watch可以直接用引号把属性括起来，就可以实现对象中特定属性的监听事件：
      this.getHeight()
    },

  },
  computed: {
    create_time () {
      return function () {
        let time = new Date(this.work.creation_time)
        let create_time_format = plugins.formatTime_ymd(time)
        return create_time_format
      }
    },
    final_width () {
      return function () {
        // 屏幕宽度低于600时忽略设置，直接占满宽度
        if (window.innerWidth < 600) {
          return '100%'
        }
        else {
          return this.work.width
        }
      }
    },
    transform_coumputed () {
      return function (index) {
        let obj = {}
        let a = index % 4 * 119
        let b = Math.floor(index / 4) * 119
        obj.transform = "translate3d(" + a + "px," + b + "px, 0px)"
        return obj
      }
    },
    upload_message () {
      return function () {
        let flag = 0
        for (let i = 0; i < this.temp_array.length; i++) {
          if (this.temp_array[i].result.length != 0) {
            flag++
          }
        }
        if (flag != this.temp_array.length) {
          return '正在上传\xa0\xa0' + flag + '/' + this.temp_array.length
        }
        else {
          return '上传完成，' + '成功' + this.upload_result.success + '张，失败' + this.upload_result.fail + '张'
        }
      }
    },
    upload_size: function () {
      // 值得注意的是，如parameter 放在了 要计算的属性后面，取到的是vm实例。
      return function (index) {
        if (this.temp_array[index].size < 1) {
          return ((this.temp_array[index].size * 1000).toFixed(1)) + 'KB'
        }
        else {
          return (this.temp_array[index].size.toFixed(1)) + 'MB'
        }
      }
    },
    upload_status: function () {

      return function (index) {
        if (this.temp_array[index].result == 'ok') {
          return '上传成功'
        }
        else {
          if (this.temp_array[index].result == 'fail') {
            return this.temp_array[index].reason
          }
          else {
            if (this.temp_array[index].percentage == 0) {
              return '排队中'
            }
            else {
              if (this.temp_array[index].percentage == -2) {
                return '正在压缩'
              }
              else {
                return (this.temp_array[index].percentage - 1) + '%'
              }
            }
          }
        }
      }

    }
  },
  methods: {
    // 编辑进入 初始化
    initialize (work) {
      console.log('work:', work)
      this.work._id = work._id
      this.work.AuthorizerAppid_id = work.appletid
      this.work.name = work.name
      this.work.edit_time = work.edit_time
      this.work.edit_time_format = work.edit_time_format
      this.work.creation_time = work.creation_time
      this.work.describe = work.describe
      // this.work.describe_height = '38px'
      this.work.public = work.public
      // this.work.menu_value = ''
      this.work.width = work.width
      this.work.old_show_create_time = work.show_create_time// 记录初始值，用于判断show_create_time是否作修改从而更新服务器数据，无论是新建还是编辑，都需要从列表页接受原始数据
      // 当新建的时候进入
      this.work.show_create_time = work.show_create_time
      this.work.old_show_view_and_like = work.show_view_and_like
      this.work.show_view_and_like = work.show_view_and_like
      this.work.subject = work.subject
      this.work.subject2 = work.subject2
      this.work.subject3 = work.subject3
      this.work.graphic_introduction_cover = Object.assign({}, work.graphic_introduction_cover)
      this.work.graphic_introduction = Object.assign([], work.graphic_introduction)
      this.cut_dialog.graphic_introduction_cover = Object.assign({}, work.graphic_introduction_cover)
      this.print('this.cut_dialog.graphic_introduction_cover:', this.cut_dialog.graphic_introduction_cover)

      this.class_edit.el_cascader_value[0] = this.work.subject
      if (this.work.subject2) {
        this.class_edit.el_cascader_value[1] = this.work.subject2
      }
      if (this.work.subject3) {
        this.class_edit.el_cascader_value[2] = this.work.subject3
      }
      this.class_edit.isResouceShow = Math.random()

      for (let i = 0; i < work.graphic_introduction.length; i++) {
        let obj = work.graphic_introduction[i]
        let item = {
          url: {
            url: obj.path,
            thumbnail_image_url: obj.path
          },
          type: obj.type,
          from: obj.from,
          percent: obj.percent,
          size: obj.size,
          content: obj.content,
          img_describe: obj.img_describe,
          layout: obj.layout,
          name: obj.name
        }
        this.upload_array.push(item)
      }
    },
    // 添加图片底部描述文字
    add_article_image_describe (value) {
      this.$prompt('图片描述：', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: value,
        closeOnClickModal: false,
      }).then(({ value }) => {
        this.article_eidt.temp.img_describe = value
      }).catch(() => {
      })
    },
    confrim_show_view_and_like () {
      let that = this
      this.$confirm('此修改将对所有数据生效，是否确认？', '', {
        cancelButtonText: '取消',
        confirmButtonText: '确认',
        showCancelButton: true,
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
      }).catch(() => {
        if (that.work.show_view_and_like == '否') {
          that.work.show_view_and_like = '是'
        }
        else {
          that.work.show_view_and_like = '否'
        }

      })
    },
    confrim_show_create_time () {
      let that = this
      this.$confirm('此修改将对所有数据生效，是否确认？', '', {
        cancelButtonText: '取消',
        confirmButtonText: '确认',
        showCancelButton: true,
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
      }).catch(() => {
        if (that.work.show_create_time == '否') {
          that.work.show_create_time = '是'
        }
        else {
          that.work.show_create_time = '否'
        }

      })
    },
    width_ratio_handleCommand (command) {
      // this.$message('click on item ' + command)
      this.work.width = command
    },
    // 保存文章编辑对话框内容
    article_edit_save () {
      this.article_eidt.dialogFormVisible = false
      console.log('article_edit_save:', this.article_eidt.index)
      if (this.article_eidt.index != -1) {
        console.log('article_edit_save:', this.article_eidt.index, this.upload_array[this.article_eidt.index])
        this.upload_array[this.article_eidt.index].layout = this.article_eidt.temp.layout
        this.upload_array[this.article_eidt.index].content = this.article_eidt.temp.content
        this.upload_array[this.article_eidt.index].img_describe = this.article_eidt.temp.img_describe
      }
      // 重置
      // 其实在进入article_edit_dialog_control函数中已经初始化，但是如果这里不在保存的时候初始化，在打开对话框的时候高度会坍塌，暂不知道原因
      let temp = {
        src: this.bg_src,
        layout: 'left',
        content: '',
        height: '78px',
        img_describe: '',
      }
      this.article_eidt.temp = temp
    },
    // 文章编辑
    article_edit_dialog_control (index = -1) {
      var that = this
      // this.print('temp:', this.article_eidt)
      this.article_eidt.dialogFormVisible = !this.article_eidt.dialogFormVisible
      this.article_eidt.index = index
      this.print('upload——array:', this.upload_array)
      if (index == -1) {
        // 重置
        let temp = {
          src: this.bg_src,
          layout: 'left',
          content: '',
          height: '78px',
          img_describe: '',
        }
        this.article_eidt.temp = temp
      }
      else {
        //赋值
        let temp = {
          src: this.upload_array[index].url.thumbnail_image_url,
          layout: this.upload_array[index].layout,
          content: this.upload_array[index].content,
          height: '78px',
          img_describe: this.upload_array[index].img_describe
        }
        this.article_eidt.temp = temp
        if (temp.content.length > 0) {
          // 如果长度大于0，则不需要计算高度，防止高度坍塌为1行
          setTimeout(() => {
            that.article_eidt.temp.height = calcTextareaHeight(that.$refs.article_edit_textarea).height
          }, 50)
        }
      }
      this.print('temp:', this.article_eidt)
    },
    // 监听高度
    getHeight () {
      this.$nextTick(() => {
        if (this.work.first_show == true) {
          this.work.first_show = false
          let that = this
          let a = calcTextareaHeight(this.$refs.textarea).height
          that.work.describe_height = 15 + parseInt(a) + 'px'         // 例子：parseInt("1234blue"); //returns 1234
          console.log('first_show:', that.work.describe_height)
        }
        else {
          this.work.describe_height = calcTextareaHeight(this.$refs.textarea).height
          console.log('height:', this.work.describe_height)
        }
      })

    },
    // 预览
    preview () {
      this.print('work:', this.work)
      /*
      if (this.drawer.drawer == false && this.upload_array.length == 0) {
        this.$confirm('请先添加图片', '', {
          confirmButtonText: '知道了',
          cancelButtonText: '不保存',
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning'
        })

        return
      }
      */
      this.drawer.drawer = !this.drawer.drawer
    },
    //input输入框失去焦点改变背景色
    lose_focus_change_bg_color () {
      this.$refs.input_title.style.backgroundColor = 'rgba(0, 0, 0, 0.16)'
    },
    // input输入框聚焦改变背景色
    foucs_change_bg_color () {
      this.$refs.input_title.style.backgroundColor = 'rgba(64 ,158 ,255,0.65)'
    },
    // 确认关闭排序
    sort_confirm_close (done) {
      this.$confirm('是否保存排序？', '提示', {
        confirmButtonText: '保存',
        cancelButtonText: '不保存',
        showCancelButton: true,
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.upload_array = Object.assign([], this.sort.temp_array)
        done()
      })
        .catch(() => {
          done()
        })
    },
    // 保存排序
    save_sort () {
      this.upload_array = Object.assign([], this.sort.temp_array)
      this.sort.dialogFormVisible = !this.sort.dialogFormVisible
    },
    // 排序开关
    sort_click () {
      console.log('sort_click')
      this.sort.dialogFormVisible = !this.sort.dialogFormVisible
      if (this.sort.dialogFormVisible) {
        this.sort.temp_array = Object.assign([], this.upload_array)
      }
    },
    // 上传处理
    file_change: async function (e) {
      // 不要将整个files作为参数传递给插件中的函数，将导致处理异常。
      var that = this
      console.log('e:', e.target.files)
      var files = e.target.files
      if (files.length === 0) {
        console.log('No file selected.')
        return
      }
      if (this.upload_array.length + files.length > this.max_upload_num) {
        console.log('too much.')
        this.$message({
          showClose: true,
          message: '最多能上传' + this.max_upload_num + '张图片',
          type: 'warning'
        })
        // 这里也需要重置,原因见函数末尾注释
        this.$refs.upload.value = null
        return
      }
      // 数据初始化
      this.temp_array = []
      this.upload_result.success = 0
      this.upload_result.fail = 0
      this.upload_active_index = -1
      for (let i = 0; i < files.length; i++) {
        let obj = {
          size: files[i].size / 1024 / 1024,
          name: files[i].name,
          percentage: 0,
          result: '',  //'ok' or 'fail'
          url: {},
          reason: '',
          from: 'temp',
          content: '',  // 正文内容
          layout: 'left', // 文字对齐方式
          img_describe: '', //图片底部文字描述
        }
        let judge_width_height = await plugins.judge_width_height(files[i])
        obj.result = judge_width_height.result
        obj.reason = judge_width_height.reason
        for (let j = 0; j < this.upload_array.length; j++) {
          if (this.upload_array[j].name == obj.name) {
            obj.result = 'fail'
            obj.reason = '该文件已上传过哦！如需重复上传，请修改文件名'
            break
          }
        }
        this.temp_array.push(obj)
      }
      this.dialogFormVisible = true
      var oss_sign = await plugins.oss_sign(that)
      if (oss_sign.result != 'ok') {
        return
      }
      for (let i = 0; i < this.temp_array.length; i++) {
        this.upload_active_index = i
        if (this.temp_array[i].result == 'fail') {
          // 前面处理过的重复文件直接不处理
          this.upload_result.fail++
        }
        else {
          let obj = await plugins.image_batch_upload_oss(that, files[i], oss_sign.data, i)
          this.temp_array[i].size = obj.size
          this.temp_array[i].result = obj.result
          this.temp_array[i].url = obj.url
          this.temp_array[i].reason = obj.reason
          // 统计成功和失败数量
          if (this.temp_array[i].result == 'ok') {
            this.upload_result.success++
            this.upload_array.push(this.temp_array[i])
          }
          if (this.temp_array[i].result == 'fail') {
            // 统计上传失败的文件
            this.upload_result.fail++
          }
        }
      }
      // 重新置-1，消除当前处理条目的背景色
      this.upload_active_index = -1
      this.print('temp_array:', this.temp_array)
      console.log('upload_array:', this.upload_array)
      // this.dialogFormVisible=false

      // 选用change事件触发上传文件，当两次选择相同文件的时候发现change事件没有触发,因此上传完成后把input的value值置空
      this.$refs.upload.value = null
    },
    // 触发input上传
    submitUpload () {
      this.$refs.upload.click()
    },

    // 选择封面
    file_single_change: async function (e) {
      console.log('e:', e)
      var that = this
      console.log('e:', e.target.files)
      var files = e.target.files
      if (files.length === 0) {
        console.log('No file selected.')
        return
      }
      // 允许重复上传
      if (files[0].name == that.cut_dialog.graphic_introduction_cover.name) {
        console.log('重复选择')
      }
      await cover_image_processing.delete_cover_image(that)

      this.print('that.cut_dialog:', that.cut_dialog)
      // 数据初始化
      that.temp_array = []
      that.upload_result.success = 0
      that.upload_result.fail = 0
      that.upload_active_index = -1
      for (let i = 0; i < files.length; i++) {
        let obj = {
          size: files[i].size / 1024 / 1024,
          name: files[i].name,
          percentage: 0,
          result: '',  //'ok' or 'fail'
          url: {},
          reason: ''
        }
        let judge_width_height = await plugins.judge_width_height(files[i])
        obj.result = judge_width_height.result
        obj.reason = judge_width_height.reason
        that.temp_array.push(obj)
      }
      that.dialogFormVisible = true
      var oss_sign = await plugins.oss_sign(that)
      if (oss_sign.result != 'ok') {
        that.$refs.upload_single.value = null
        return
      }
      for (let i = 0; i < that.temp_array.length; i++) {
        that.upload_active_index = i
        if (that.temp_array[i].result == 'fail') {
          // 前面处理过的重复文件直接不处理
          that.upload_result.fail++
        }
        else {
          let obj = await plugins.image_batch_upload_oss(that, files[i], oss_sign.data, i)
          that.temp_array[i].size = obj.size
          that.temp_array[i].result = obj.result
          that.temp_array[i].url = obj.url
          that.temp_array[i].reason = obj.reason
          // 统计成功和失败数量
          if (that.temp_array[i].result == 'ok') {
            that.upload_result.success++
            // that.upload_array.push(that.temp_array[i])
          }
          if (that.temp_array[i].result == 'fail') {
            // 统计上传失败的文件
            that.upload_result.fail++
          }
        }
      }
      // 重新置-1，消除当前处理条目的背景色
      that.upload_active_index = -1
      that.print('temp_array:', that.temp_array)
      if (that.temp_array[0].result == 'fail') {
        return             // 上传失败
      }
      const img = new Image()
      img.src = that.temp_array[0].url.url
      // img.crossOrigin = '*' // 支持跨域图片
      img.onload = function () {
        console.log(img, img.width + ',height:' + img.height)
        // 下面的top left初始化并不起作用
        /*
        that.$refs.cover_img.style.top = 0
        that.$refs.cover_img.style.left = 0
        */
        // 图片保存到服务器时宽度被拉伸为980px，封面比例3:2,所以高度至少不低于653px。判断后如果高度不够则取最小高度
        if (img.height < 653) {
          // 高度不够
          that.$refs.cover_img.style.height = '653px'
          // 宽度此时也要作等比例的扩大
          that.$refs.cover_img.style.width = (img.width * 653 / img.height) + 'px'
        }
        else {
          that.$refs.cover_img.style.height = img.height + 'px'
          that.$refs.cover_img.style.width = img.width + 'px'
        }
        that.cut_dialog.temp_graphic_introduction_cover.original_image_url = that.temp_array[0].url.url
        that.cut_dialog.temp_graphic_introduction_cover.name = that.temp_array[0].name
        that.cut_dialog.temp_graphic_introduction_cover.from = 'temp'
        that.cut_dialog.temp_graphic_introduction_cover.size = that.temp_array[0].size
      }
      that.$refs.upload_single.value = null
      // that.dialogFormVisible=false
    },
    //触发封面input上传
    submitSingleUpload () {
      this.$refs.upload_single.click()
    },
    // 取消或者进入编辑封面对话框
    cancel_cut () {
      this.cut_dialog.dialogTableVisible = !this.cut_dialog.dialogTableVisible
      if (this.cut_dialog.dialogTableVisible == true) {
        this.cut_dialog.temp_graphic_introduction_cover = Object.assign({}, this.cut_dialog.graphic_introduction_cover)
        // 延时防止报错，在打开对话框时，元素还来不及渲染
        /*
        setTimeout(() => {
          that.$refs.cover_img.style.height = that.cut_dialog.graphic_introduction_cover.style.height + 'px'
          that.$refs.cover_img.style.width = that.cut_dialog.graphic_introduction_cover.style.width + 'px'
        }, 0)
        */
      }
      if (this.cut_dialog.dialogTableVisible == false) {
        // 这里不能简单的进行cover_image_processing.delete_cover_image(this)操作，这样在删除temp_graphic_introduction_cover中的临时
        // 图片信息时也一并删除掉了graphic_introduction_cover中的临时图片信息，因为temp_graphic_introduction_cover是在进入裁剪框
        // 时通过graphic_introduction_cover赋值的。在这种情况下只能在退出页面时进行处理，如果未点击保存，且图片来自临时文件夹则删除。
        // 存在另外的一种情况，重新选择了图片，但是未点击保存，这样temp_graphic_introduction_cover和graphic_introduction_cover中的
        // original_image_url值是不同的，所以通过比较后才能判断是否进行删除操作。
        cover_image_processing.delete_cover_image(this)
      }
    },
    // 返回上页
    return_back () {
      if (!this.$route.query.work && this.cut_dialog.graphic_introduction_cover.path.length != 0) {
        // 新建进入且已上传必要内容(封面图片)的前提下弹出对话框
        let that = this
        this.$confirm('您还未保存，确定要离开吗？', '', {
          cancelButtonText: '取消',
          confirmButtonText: '确认',
          showCancelButton: true,
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          that.$router.go(-1)
        }).catch(() => { })
        return
      }
      this.$router.go(-1)
    },
    return_last_layer () {
      this.$router.go(-1)
    },
    // 保存退出
    save_and_quit () {
      let that = this
      if (this.cut_dialog.graphic_introduction_cover.path.length == 0) {
        this.$confirm('您还未上传封面', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '上传',
          showCancelButton: true,
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          that.cancel_cut()
        }).catch(() => { })
        return
      }
      if (this.work.describe.length == 0) {
        this.$confirm('您还未添加文字（题记）', '提示', {
          cancelButtonText: '',
          confirmButtonText: '知道了',
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
        }).catch(() => { })
        return
      }
      if (this.class_edit.el_cascader_value.length == 0) {
        this.$confirm('您还未选择分类', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '打开设置',
          showCancelButton: true,
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          that.click_button('set')
        }).catch(() => { })
        return
      }
      //开始整理数据
      console.log('save success')
      let temp = that.$store.state.accept_data
      that.work.AuthorizerAppid_id = temp.AuthorizerAppid_id
      that.work.subject = this.class_edit.el_cascader_value[0]
      that.work.subject2 = this.class_edit.el_cascader_value[1] || ''
      that.work.subject3 = this.class_edit.el_cascader_value[2] || ''
      that.work.name = that.work.name || '标题未命名'
      let op_type = ''
      if (!this.$route.query.work) {
        // 新建状态进入
        op_type = 'formal_edit_new'
        that.work.graphic_introduction_cover = that.cut_dialog.graphic_introduction_cover
        for (let i = 0; i < that.upload_array.length; i++) {
          let temp_item = {
            path: that.upload_array[i].url.url,
            type: 'image',
            from: 'temp',
            percent: 100,
            size: that.upload_array[i].size,
            content: that.upload_array[i].content,
            img_describe: that.upload_array[i].img_describe,
            layout: that.upload_array[i].layout,
            name: that.upload_array[i].name
          }
          that.work.graphic_introduction.push(temp_item)
        }
      }
      else {
        // 编辑状态进入,先删除之前的正式文件夹图片
        // that.work.graphic_introduction_cover = that.cut_dialog.graphic_introduction_cover
        op_type = 'formal_edit_toedit'
        if (that.work.graphic_introduction_cover.original_image_url != that.cut_dialog.graphic_introduction_cover.original_image_url) {
          console.log('封面已变更，删除之前的正式图片,然后将临时图片赋值给work')
          let file = that.work.graphic_introduction_cover.original_image_url
          let file_temp = file.split('?')[0] //截取参数前面的文件路径
          let file_name = that.work.graphic_introduction_cover.from + '/' + file_temp.substr(file_temp.lastIndexOf('/') + 1)
          plugins.oss_delete(this, file_name)
          that.work.graphic_introduction_cover = that.cut_dialog.graphic_introduction_cover
        }
        else {
          console.log('封面未变更，最多只是截图区域调整,仍然使用原图')
          that.work.graphic_introduction_cover = that.cut_dialog.graphic_introduction_cover
        }
        // 处理graphic_introduction
        for (let i = 0; i < this.work.graphic_introduction.length; i++) {
          // 检查在upload_array中是否存在，如果不存在表明需要删除
          let exit_flag = false
          for (let j = 0; j < this.upload_array.length; j++) {
            if (this.upload_array[j].url.url == this.work.graphic_introduction[i].path) {
              exit_flag = true
              break
            }
          }
          if (exit_flag == false) {
            console.log('需要删除')
            let file = that.work.graphic_introduction[i].path
            let file_temp = file.split('?')[0] //截取参数前面的文件路径
            let file_name = that.work.graphic_introduction[i].from + '/' + file_temp.substr(file_temp.lastIndexOf('/') + 1)
            plugins.oss_delete(this, file_name)
          }
        }
        that.work.graphic_introduction = []  // 清空
        for (let i = 0; i < that.upload_array.length; i++) {
          let temp_item = {
            path: that.upload_array[i].url.url,
            type: 'image',
            from: that.upload_array[i].from,
            percent: 100,
            size: that.upload_array[i].size,
            content: that.upload_array[i].content,
            img_describe: that.upload_array[i].img_describe,
            layout: that.upload_array[i].layout,
            name: that.upload_array[i].name
          }
          that.work.graphic_introduction.push(temp_item)
        }
      }
      that.print('work:', that.work)
      let content = {
        type: op_type,
        phone: temp.phone,
        session: that.$store.state.session,
        work: that.work
      }
      plugins.bms_update(that, content, '正在上传')
        .then((value) => {
          that.drawer.before_quit_drawer = true
          that.drawer.preview_return_data = value.return_data
          this.$confirm('保存成功', '', {
            confirmButtonText: '知道了',
            showCancelButton: false,
            closeOnClickModal: false,
            showClose: false,
            type: 'success'
          }).then(() => {
          })
        })
        .catch(() => {
          this.$confirm('上传失败，请稍后再试', '提示', {
            confirmButtonText: '知道了',
            showCancelButton: false,
            closeOnClickModal: false,
            type: 'error'
          }).then(() => {
          })
        })
    },
    // 点击中间选项按钮
    click_button (set) {
      this.active_button = set
    },
    cover_image_processing (type, param) {
      param = param || null
      // console.log('param:', param)
      cover_image_processing.enter_function(this, type, param)
    },
    delete_img (index) {
      let delete_item = this.upload_array.splice(index, 1)[0]
      this.print('delete_item:', delete_item)
      // 如果是临时图片直接删除，正式图片不做处理
      if (delete_item.from == 'temp') {
        let file = delete_item.url.url
        let file_temp = file.split('?')[0] //截取参数前面的文件路径
        let file_name = 'temp/' + file_temp.substr(file_temp.lastIndexOf('/') + 1)  //这里肯定是来自temp文件夹
        plugins.oss_delete(this, file_name)
      }
    },
    // 解决vue-cropper访问图片跨域问题
    /*
    img_cropper_cross_domain (src) {
      var that = this
      // 将网络图片转换成base64格式
      function transBase64FromImage (image) {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        // 可选其余值 image/jpeg
        return canvas.toDataURL("image/png");
      }
      const img = new Image()
      img.src = src
      img.crossOrigin = '*' // 支持跨域图片
      img.onload = function () {
        console.log('img:', img)
        let base64 = transBase64FromImage(img)
        that.option.img = base64
      }
    },
    */
  }
}
</script>
<style>
@import '../assets/scss/icon.scss';
@import '../assets/scss/common.scss';
@import '../assets/scss/upload.scss';
@import '../assets/scss/formaledit.scss';
@import '../assets/scss/articleView.scss';
</style>
