<template>
  <div class="listshow temp_common">
    <div class="base_message_title" style="margin-bottom: 20px">
      {{ current_menu.name }}
      <!--
      <div class="modify_menubtn" style="">
        <i class="icon-setting2"></i> <span>高级设置</span>
      </div>
      -->
      <el-popover placement="bottom" width="150" trigger="hover">
        <a
          class="temp_a"
          @click="class_edit_function('create_classTable', { menu: true })"
        >
          <i class="iconfont iconbianjishuru"></i
          ><span class="temp_span">岗位管理</span>
        </a>
        <el-button slot="reference">
          <i class="iconfont iconshezhi"></i
          ><span class="temp_span" style="color: #fff"
            >高级设置</span
          ></el-button
        >
      </el-popover>
    </div>
    <div class="order_filter_wrap">
      <!--
      <el-button size="small" @click.stop="image_upload('单图上传')"
        >＋单图上传</el-button
      >
      -->
      <el-button type="primary" size="medium" @click.stop="new_account()"
        >＋新建子账号</el-button
      >
      <div
        class="verticalAlign fenlei_choose"
        style="float: right; margin-top: -5px"
      >
        <span
          class="
            text_margin_btn_right
            white_color
            text_margin_btn_left
            verticalAlign
          "
          >筛选</span
        >
        <el-cascader
          :key="class_edit.isResouceShow"
          v-model="class_edit.el_cascader_value"
          :options="class_edit.options"
          @change="el_cascader_change"
        ></el-cascader>
      </div>
    </div>
    <div class="temp_request_area" v-loading="el_loading">
      <template v-if="!el_loading">
        <el-empty
          description="还没有子账号，快去新建吧~"
          :image-size="120"
          v-if="request_data.length == 0"
        ></el-empty>
        <div
          v-for="(item, index) in request_data"
          v-bind:key="index"
          class="account_list_item"
        >
          <div style="padding: 20px">
            <el-descriptions
              class="margin-top"
              title="子账号"
              :column="3"
              size="small"
            >
              <template slot="extra">
                <el-dropdown
                  trigger="click"
                  size="small"
                  @command="account_operate"
                >
                  <el-button type="primary" size="mini">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-edit"
                      :command="{ item: item, command: '编辑' }"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-delete"
                      :command="{ item: item, command: '删除' }"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <el-descriptions-item label="用户名">{{
                item.bms_name
              }}</el-descriptions-item>
              <el-descriptions-item label="绑定手机号">{{
                item.phone
              }}</el-descriptions-item>
              <el-descriptions-item label="岗位"
                ><el-tag size="mini">{{
                  position_conversion(item.bms_position)
                }}</el-tag></el-descriptions-item
              >
              <el-descriptions-item label="最近登录">{{
                last_login_time(item.bms_last_login_time)
              }}</el-descriptions-item>
              <el-descriptions-item
                label="备注"
                labelStyle="white-space:nowrap;"
                contentStyle="width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;margin-right:90px;"
              >
                <span
                  :title="item.bms_note.length > 0 ? item.bms_note : '未填写'"
                  >{{
                    item.bms_note.length > 0 ? item.bms_note : '未填写'
                  }}</span
                >
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
        <div style="margin-top: 60px">
          <el-pagination
            background
            :total="el_pagination.total"
            layout="total, prev, pager, next"
            :page-size="el_pagination.page_size"
            @current-change="el_current_change"
          >
          </el-pagination>
        </div>
      </template>
    </div>

    <!--岗位编辑弹出框-->
    <template>
      <el-dialog
        title="岗位管理"
        center
        :visible.sync="class_edit.dialogTableVisible"
        width="750px"
        :close-on-click-modal="false"
      >
        <template v-if="class_edit.tableData.length != 0">
          <el-table
            :data="class_edit.tableData"
            style="width: 100%"
            row-key="value"
            :default-expand-all="false"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column label="岗位名称" width="320">
              <template slot-scope="scope">
                <span style="margin-left: 10px"
                  >({{ scope.row.layer }}) {{ scope.row.label }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="
                    class_edit_function('rename_sub_class', { row: scope.row })
                  "
                  >重命名</el-button
                >
                <el-button
                  size="mini"
                  @click="
                    class_edit_function('team_account_auth_set', {
                      row: scope.row,
                      title: '权限设置',
                    })
                  "
                  v-if="scope.row.layer < 3"
                  style="margin-right: 10px"
                  >设置权限</el-button
                >
                <el-popconfirm
                  title="确定删除该岗位吗？"
                  @confirm="
                    class_edit_function(
                      'delete_sub_class_from_team_management',
                      {
                        row: scope.row,
                      }
                    )
                  "
                >
                  <el-button
                    slot="reference"
                    size="mini"
                    type="danger"
                    :loading="class_edit.delete_row_value == scope.row.value"
                    >删除</el-button
                  >
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <ul class="explain_list">
            <li>小贴士：请为每个岗位设置操作权限</li>
          </ul>
        </template>
        <template v-else>
          <el-empty description="空空如也~" :image-size="120"></el-empty>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button
            style="margin-right: 30px"
            size="small"
            @click="
              class_edit_function('add_sub_class', {
                row: {},
                title: '添加岗位',
              })
            "
            >添加岗位</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click.stop="class_edit_function('save_and_quit', { menu: true })"
            >保存更改</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        :title="class_edit.dialog_title"
        center
        :visible.sync="class_edit.dialogFormVisible"
        width="500px"
        :close-on-click-modal="false"
      >
        <div style="margin: 0 30px">
          <el-input
            type="text"
            v-model="class_edit.input_value"
            placeholder="请输入岗位名称"
          >
            <span slot="suffix" class="slot_span"
              ><span
                :style="{
                  color: class_edit.count_input_word > 12 ? '#F56C6C' : '',
                }"
                >{{ class_edit.count_input_word }}</span
              >/12</span
            >
          </el-input>
          <ul class="explain_list">
            <li>最多可添加50个自定义岗位</li>
            <li>岗位名称最大支持6个汉字或12个英文</li>
          </ul>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button
            size="small"
            @click.stop="class_edit.dialogFormVisible = false"
            style="margin-right: 30px"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click.stop="class_edit_function('confirm_input_change')"
            >确定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        :title="class_edit.dialog_title"
        center
        :visible.sync="class_edit.auth.dialogAuthVisible"
        width="700px"
        :close-on-click-modal="false"
        :destroy-on-close="true"
      >
        <div style="padding: 0 10px 0 35px">
          <div style="margin-bottom: 35px">
            <el-button type="primary" disabled size="small"
              >{{ class_edit.auth.positon_label }}岗位</el-button
            >
          </div>
          <div style="font-size: 12px; color: #bcb8b8; margin-bottom: 15px">
            <span>请勾选需要授权的功能：</span>
          </div>
          <el-checkbox
            v-model="class_edit.auth.checkAll"
            @change="handleCheckAllChange"
            border
            size="small"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0"></div>
          <el-checkbox-group
            v-model="class_edit.auth.checkedAuth"
            size="small"
            @change="handleCheckedCitiesChange"
          >
            <div
              style="display: inline-block; margin: 0 15px 15px 0"
              v-for="item in class_edit.auth.auth_array"
              :key="item.value"
            >
              <el-checkbox :label="item.value" border>{{
                item.name
              }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button
            size="small"
            @click.stop="class_edit.auth.dialogAuthVisible = false"
            style="margin-right: 30px"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click.stop="class_edit_function('confirm_auth_set')"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </template>

    <!--子账号编辑对话框-->
    <el-dialog
      :title="account.title"
      center
      :visible.sync="account.dialogTableVisible"
      width="420px"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div style="padding: 0 60px 0 30px">
        <el-form
          ref="ruleForm"
          :model="account.form"
          label-width="80px"
          size="small"
          :rules="account.rules"
        >
          <el-form-item label="用户名" prop="name">
            <el-input v-model="account.form.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model.number="account.form.phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="岗位" prop="position">
            <el-select v-model="account.form.position" placeholder="请选择">
              <el-option
                v-for="item in account.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="note">
            <el-input v-model="account.form.note"></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 40px">
            <el-button
              v-show="account.title == '新建子账号'"
              type="primary"
              @click="submitForm('ruleForm')"
              style="margin-right: 15px"
              :loading="account.submit_button_loading"
              >立即创建</el-button
            >
            <el-button
              v-show="account.title == '编辑子账号'"
              type="primary"
              @click="submitForm('ruleForm')"
              style="margin-right: 15px"
              :loading="account.submit_button_loading"
              >保存更改</el-button
            >
            <el-button @click.stop="account.dialogTableVisible = false"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import class_edit from '../assets/js/class_edit';
import plugins from '../assets/js/plugin';
export default {
  props: {
    current_menu: Object,
    current_menu_index: Number
  },
  data () {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请填写手机号'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('手机号必须为数字'));
        } else {
          console.log('value.length:', value.toString().length)
          if (value.toString().length != 11) {
            callback(new Error('手机号位数为11位'));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    return {

      //父组件传参
      menu_index: this.current_menu_index,

      //请求数据
      request_data: [],
      el_loading: true,  //空内容图标控制器

      //岗位编辑控制参数
      class_edit: {
        dialogTableVisible: false,
        el_cascader_value: ['全部'],
        options: [{           // 含首元素{value: '全部',label: '全部'}
          value: '全部',
          label: '全部'
        }],
        tableData: [],   //用于分类编辑，不含首元素{value: '全部',label: '全部'}
        input_value: '',
        count_input_word: 0,
        class_row: {},
        dialog_title: '标题',
        dialogFormVisible: false,
        // 当多个菜单项共用一个模板时，如果各自的el-cascader联级目录层数不一样，那么在选定层数较深的一方后，再切换菜单到层数较少的一方，
        // 会导致控制台报错，原因是两者共用一个模板导致没有重新渲染页面，但是绑定的数组数据却改变了(可以看到，el数组
        //数据变化后并不会刷新页面)，从而报错，解决方法也就是需要重新刷新
        // 页面，这样通过绑定一个变量，改变其值来刷新页面。参见：https://blog.csdn.net/qq_37253540/article/details/100730223
        isResouceShow: 0,
        delete_row_value: '', // 删除状态

        auth: {
          dialogAuthVisible: false, // 权限设置，用于团队管理模块
          auth_array: [],  //所有可供选择的权限集合
          checkedAuth: [],  // 已选中的权限集合
          positon_label: '', //选取的岗位信息名称
          position_value: '',//选取的岗位信息value,唯一标识
          checkAll: false,      //全选按钮是否选中状态
          // isIndeterminate: true,  //该属性用以表示 checkbox 的不确定状态，一般用于实现全选的效果
        }
      },

      // 分页参数
      el_pagination: {
        total: 0,
        page_size: 10,
        current_page: 1
      },

      // 新建子账号
      account: {
        dialogTableVisible: false,
        title: '',
        options: [],
        submit_button_loading: false,
        edit_id: null,
        form: {
          name: '',  // 账号名
          phone: '',    // 手机号
          position: '',   // 岗位
          note: '', //备注
        },
        rules: {
          name: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入绑定手机号码', trigger: 'blur' },
            { validator: checkPhone, trigger: 'blur' }
          ],
          position: [
            { required: true, message: '请选择岗位', trigger: 'blur' }
          ],
          note: [
            { min: 0, max: 100, message: '备注过长', trigger: 'blur' }
          ]
        }
      },

      // delete操作，用于服务器判断是否进行数量统计
      delete_op: false
    }
  },
  // 相当于onlaunch()
  // vue实例的数据对象data在钩子函数created()执行的时候已经有了
  created () {
    this.initialize_data()
  },
  mounted () {
    this.$nextTick(() => {
      // es6语法，拼接数组 https://blog.csdn.net/cristina_song/article/details/82805444
      // 在最初服务器端设置menu的时候，分类菜单的options被置为[],所以是一定存在的
      // console.log('this.current_menu:', this.current_menu)
      this.class_edit.options.push(...this.current_menu.options)
    })
  },
  beforeDestroy () {
  },
  watch: {
    current_menu_index: { //深度监听，可监听到对象、数组的变化
      //监听current_menu_index，，由于此属性绑定的是父组件的变量，所以实际上监听的是父组件变量的变化
      // 由于部分菜单共用模板，在这些模板之间切换不会导致数据重新渲染，这里通过监听index的改变来刷新数据
      handler () {
        this.class_edit.options = [{
          value: '全部',
          label: '全部'
        }]
        this.class_edit.el_cascader_value = ['全部']
        this.class_edit.options.push(...this.current_menu.options)
        this.menu_index = this.current_menu_index
        this.class_edit.isResouceShow = Math.random()
        this.el_pagination.total = 0
        this.el_pagination.current_page = 1
        this.el_loading = true
        // this.initialize_data() //当接连点击不同菜单选项(共用相同组件)时，页面不会被重新加载,需要监听下标变化从而刷新数据
      },
      deep: true
    },
    //普通的watch监听
    'class_edit.input_value' (newVal, oldVal) {  // 对象具体属性的watch可以直接用引号把属性括起来，就可以实现对象中特定属性的监听事件：
      console.log('input_value:', newVal, oldVal)
      this.class_edit.count_input_word = newVal.length
      //中文、中文标点、全角字符按2长度，英文、英文符号、数字按1长度计算
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
      let mat = newVal.match(cnReg);
      if (mat) {
        this.class_edit.count_input_word = mat.length * 2 + (newVal.length - mat.length)
      } else {
        this.class_edit.count_input_word = newVal.length
      }
    }

  },
  computed: {
    position_conversion () {
      return function (value) {
        // console.log('position_conversion:', value)
        for (let i = 0; i < this.class_edit.options.length; i++) {
          if (this.class_edit.options[i].value == value) {
            return this.class_edit.options[i].label
          }
        }
      }
    },
    last_login_time () {
      return function (login_time) {
        let time = new Date(login_time)
        let create_time_format = plugins.formatTime_ymdhms(time)
        return create_time_format
      }
    },
  },
  methods: {
    // 分页
    el_current_change (val) {
      console.log(`当前页: ${val}`)
      console.log('e:', val)
      this.el_pagination.current_page = val
      this.initialize_data()
    },
    // 数据初始化
    initialize_data () {
      console.log('initialize_data')
      let that = this
      let temp = that.$store.state.accept_data
      let content = {
        type: 'temp_team_management_load',
        phone: temp.phone,
        session: that.$store.state.session,
        current_page: this.el_pagination.current_page,
        page_size: this.el_pagination.page_size,
        AuthorizerAppid: temp.AuthorizerAppid,
        delete_op: this.delete_op,
        position: this.class_edit.el_cascader_value[0]
      }
      plugins.bms_download(this, content)
        .then((res) => {
          that.print('res:', res)
          this.el_loading = false
          this.request_data = []
          this.request_data = res.page_data
          if (res.count > 0) {
            this.el_pagination.total = res.count
          }
        })
        .catch((err) => {
          console.log('plugins.bms_download catch:', err)
          this.el_loading = false
        })
    },
    // 多选框值改变触发函数，当绑定值变化时触发的事件(即绑定数组的值增多或者减少)
    handleCheckedCitiesChange (value) {
      // console.log('value:', value)
      let checkedCount = value.length;
      this.class_edit.auth.checkAll = checkedCount === this.class_edit.auth.auth_array.length;
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    // 全选状态改变触发函数
    handleCheckAllChange (val) {
      let that = this
      if (val) {
        let temp_array = []
        for (let i in that.class_edit.auth.auth_array) {
          temp_array.push(that.class_edit.auth.auth_array[i].value)
        }
        that.class_edit.auth.checkedAuth = Object.assign([], temp_array)
      }
      else {
        that.class_edit.auth.checkedAuth = []
      }
    },
    el_cascader_change () {
      console.log("el_cascader_change")
      this.el_pagination.total = 0
      this.el_pagination.current_page = 1
      this.initialize_data()
    },
    new_account () {
      let that = this
      if (this.el_pagination.total > 50) {
        this.$confirm('子账号数已达50个，无法增加更多了', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '知道了',
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning'
        })
        return
      }
      // 以下重置要放开头，这样执行速度快一些
      this.account.title = '新建子账号'
      let form = {
        name: '',
        phone: '',
        position: '',
        note: ''
      }
      this.account.form = Object.assign({}, form)
      this.account.options = JSON.parse(JSON.stringify(this.class_edit.options))
      this.account.options.splice(0, 1)
      that.print('account.options:', this.account.options)
      if (this.current_menu.options.length == 0) {
        this.$confirm('需要先创建岗位', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '创建岗位',
          showCancelButton: true,
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          // 不用延迟的话，对话框之间切换会闪屏
          setTimeout(() => { that.class_edit_function('create_classTable', { menu: true }) }, 200)
        }).catch(() => { })
        return
      }
      this.account.dialogTableVisible = true
    },
    edit_account (item) {
      this.account.title = '编辑子账号'
      this.account.dialogTableVisible = true
      this.account.edit_id = item._id
      let form = {
        name: item.bms_name,
        phone: 1 * item.phone,
        position: item.bms_position,
        note: item.bms_note
      }
      this.account.form = Object.assign({}, form)
      this.account.options = JSON.parse(JSON.stringify(this.class_edit.options))
      this.account.options.splice(0, 1)
    },
    delete_account (item) {
      let that = this
      that.print('delete_account:', item)
      this.$confirm('删除后，对应手机号将无法登录，是否确认?', '您是否要删除该子账号？', {
        cancelButtonText: '取消',
        confirmButtonText: '确认',
        showCancelButton: true,
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        let content = {
          type: 'delete_account',
          session: that.$store.state.session,
          _id: item._id
        }
        that.delete_op = true
        plugins.bms_update(that, content).then(() => {
          that.initialize_data()
          that.delete_op = false
          this.$confirm('删除成功', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            showCancelButton: false,
            closeOnClickModal: false,
            type: 'success'
          })
        }).catch(() => {
          that.delete_op = false
        })

      }).catch(() => { })
    },
    account_operate (command) {
      console.log('command:', command)
      if (command.command == '编辑') {
        this.edit_account(command.item)
      }
      if (command.command == '删除') {
        this.delete_account(command.item)
      }
    },
    submitForm (formName) {
      let that = this
      // 这里验证有延迟，防止多次提交
      that.account.submit_button_loading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!')
          let temp = that.$store.state.accept_data
          if (that.account.form.phone == temp.phone) {
            that.account.submit_button_loading = false
            this.$confirm('该手机号为管理员绑定号码，不支持此操作', '提示', {
              cancelButtonText: '取消',
              confirmButtonText: '确认',
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'error'
            })
            return
          }
          let content = {
            type: this.account.title == '编辑子账号' ? 'edit_account' : 'new_account',
            // phone: temp.phone,
            session: that.$store.state.session,
            AuthorizerAppid: temp.AuthorizerAppid,
            vip_expiration_time: temp.vip_expiration_time,
            form: that.account.form,
            edit_id: this.account.edit_id
          }
          that.print('content:', content)
          plugins.bms_update(that, content, '').then((data) => {
            that.print('return data:', data)
            that.account.submit_button_loading = false
            if (data.content == '创建成功' || data.content == '更新成功') {
              this.account.dialogTableVisible = false
              that.$confirm(data.content, '', {
                cancelButtonText: '取消',
                confirmButtonText: '确认',
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'success'
              })
              that.initialize_data()
            }
            if (data.content == '子账号已存在，请在列表中直接进行编辑') {
              that.$confirm(data.content, '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确认',
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'warning'
              })
            }
            if (data.content == '手机号已绑定其他子账号，一个手机号只能绑定一个账号哦') {
              that.$confirm(data.content, '更新失败', {
                cancelButtonText: '取消',
                confirmButtonText: '确认',
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'error'
              })
            }
          }).catch(() => {
            that.account.submit_button_loading = false
          })
        } else {
          console.log('error submit!!')
          that.account.submit_button_loading = false
          return false
        }
      })
    },
    // 岗位编辑控制函数
    class_edit_function (type, param) {
      // this.print('this.class_edit.options:', this.class_edit.options)
      param = param || null
      //console.log('param:', param)
      class_edit.class_edit_function(this, type, param)
    },
  },
}
</script>
<style scoped>
@import '../assets/scss/temp_common.scss';
@import '../assets/scss/upload.scss';
</style>