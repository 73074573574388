<template>
  <div></div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
  },
}
</script>
<style scoped>
</style>