<template>
  <div class="applet_authorization">
    <div class="applet_authorization_container">
      <div class="authorization_box">
        <div class="authorization_item authorization_old">
          <div class="au_center_box">
            <div class="au_center_box_son">
              <div class="au_center_box_top">
                <span class="one_word">直</span>接授权
              </div>
              <div class="au_center_box_middle">
                已经有自己的小程序，点击下方授权按钮后会跳转到微信公众平台，扫一扫即完成授权。
              </div>
              <div class="au_center_box_bottom">
                <a
                  class="get_one"
                  @click="
                    gourl(
                      'https://mp.weixin.qq.com/cgi-bin/componentloginpage',
                      'directly'
                    )
                  "
                  >我已有小程序，直接授权</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="authorization_item authorization_new">
          <div class="au_center_box">
            <div class="au_center_box_son">
              <div class="au_center_box_top">
                <span class="one_word">注</span>册小程序
              </div>
              <div class="au_center_box_middle">
                <p>注册新的小程序账号，并扫码完成授权即可。</p>
                <p>技术支持手机号：15173082639</p>
              </div>
              <div class="au_center_box_bottom">
                <a class="get_one" @click="gourl1()">注册新的小程序</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'operator',
  data () {
    return {
      appid: 'abc'
    }
  },
  methods: {
    gourl1: function () {
      this.$alert('请添加客服微信：15173082639，只需一分钟即可开通服务！', '提示', {
        confirmButtonText: '确定'
      })
    },
    gourl: function (url, type) {
      // 如果完整引入了 Element，那么 Vue.prototype 上会有一个全局方法 $loading，它的调用方式为：this.$loading(options)
      let loadingInstance = this.$loading('跳转中')
      // window.location.href="https://www.baidu.com"这种方法是在原标签页打开
      // window.open(url)
      let param = new URLSearchParams()
      param.append('username', 'abcdefga$##$##@')
      param.append('password', type)
      this.$ajax({
        url: '/zhitui/zuomian_get_pre_auth_code',
        method: 'post',
        data: param,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .catch((response) => {
          loadingInstance.close()
          console.log(response)
          this.$alert('网络错误!', '提示', {
            confirmButtonText: '确定'
          })
        })
        .then((response) => {
          loadingInstance.close()
          console.log('response:', response)
          if (response.status === 200 && typeof (response.data) === 'object') {
            let now = url + '?component_appid=' + response.data.component_appid + '&pre_auth_code=' + response.data.pre_auth_code + '&redirect_uri=' + response.data.redirect_uri + '&auth_type=2'
            window.open(now)
          } else {
            this.$alert('网络错误，请重试。', '提示', {
              confirmButtonText: '确定'
            })
          }
        })
    },
  }
}
</script>
<style scoped>
@import '../assets/scss/icon.scss';
@import '../assets/scss/applet_authorization.scss';
/*在Vue开发中，会遇到html被浏览器解析后，在标签中出现’data-v-xxxxx’标记，如下：
<div data-v-fcba8876 class="xxx"> aaa</div>
因为要保证各文件中的css不相互影响，给每个component都做了唯一的标记，所以每引入一个component就会出现一个新的'data-v-xxx'标记
*/
/*设置宽度：width:calc(100vw - 123px);
说明：1、vw是width of view(port)的缩写；2、100vw表示百分之百的视图宽度；3、123px是需要减去的宽度；
4、减号的两边必须都有至少一个空格。设置高度：height:calc(100vh-123px);说明：1、vh是height of view(port)的缩写；
2、100vh表示百分之百的视图高度；3、123px是需要减去的高度；4、同理减号的两边必须都有至少一个空格
*/
</style>
