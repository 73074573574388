<template>
  <div class="uploadFile">
    <div class="series_edit_action_selector">
      <div
        v-for="(item, index) in series.array"
        v-bind:key="index"
        class="series_edit_selection"
        :class="{ active: series.edit_name == item }"
        @click.stop="choose_series_item(item)"
      >
        {{ item }}
      </div>
    </div>
    <div class="seires_edit_wrap" style="padding-left: 70px">
      <div style="display: block">
        <div class="ant-row">
          <div class="series_edit_form_item_title">批量上传</div>
          <div class="series_edit_form_item_content">
            <div class="series_image_item" v-show="upload_array.length < 100">
              <div
                style="width: 0px; height: 0px; opacity: 0; overflow: hidden"
              >
                <input
                  ref="upload"
                  @change="file_change($event)"
                  name="upload"
                  type="file"
                  accept="image/*"
                  multiple
                />
              </div>
              <div
                class="series_image_item_upload_in"
                @click.stop="submitUpload()"
              >
                <i class="el-icon-circle-plus-outline"></i>
                <div style="font-size: 12px">点击上传</div>
              </div>
            </div>

            <div
              v-for="(item, index) in upload_array"
              v-bind:key="index"
              class="series_image_item"
              :style="{
                backgroundImage: 'url(' + item.url.thumbnail_image_url + ')',
              }"
            >
              <div class="series_image_item_del_btn">
                <el-popconfirm
                  title="确定要删除这张图片吗？"
                  @confirm="delete_img(index)"
                >
                  <div style="width: 100%" slot="reference">删除</div>
                </el-popconfirm>
              </div>
            </div>
            <div style="color: rgb(170, 170, 170); margin-top: 5px">
              备注：每次最多能上传100张图片，超过100张请分批次上传
            </div>
            <div style="color: rgb(170, 170, 170)">
              统计：当前已成功上传{{ upload_array.length }}张，{{
                100 - upload_array.length > 0
                  ? '还能上传' + (100 - upload_array.length)
                  : '请保存后再上传'
              }}张
            </div>
          </div>
        </div>
        <div class="ant-row">
          <div class="series_edit_form_item_title">分类选择</div>
          <div class="series_edit_form_item_content">
            <div class="series_cascader">
              <el-cascader
                :key="class_edit.isResouceShow"
                v-model="class_edit.el_cascader_value"
                :options="class_edit.options"
                size="small"
              ></el-cascader>
              <span
                style="margin-left: 10px; color: #409eff; cursor: pointer"
                @click="
                  class_edit_function('create_classTable', { menu: false })
                "
                >管理分类</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="series_edit_saves">
      <el-button size="small" @click.stop="cancel_upload('返回上页')"
        >取消</el-button
      >
      <el-button
        type="primary"
        size="small"
        :loading="save_prompt.save_loading"
        @click="save_and_quit"
        >{{ save_prompt.save_loading ? '保存中' : '保存' }}</el-button
      >
    </div>

    <!--上传进度显示-->
    <el-dialog :visible.sync="dialogFormVisible" width="900px">
      <div slot="title">
        <div class="upload_title">{{ upload_message() }}</div>
        <el-popover placement="bottom" width="390" v-model="problem_visible">
          <div class="problem_content">
            <p style="font-size: 14px; margin-bottom: 10px">
              <i
                class="el-icon-info"
                style="color: #e3912b; font-size: 20px"
              ></i>
              上传遇到问题?
            </p>
            <p style="margin-bottom: 15px">请检查以下情况后，刷新页面重试：</p>
            <p style="color: #8d8a8a">
              <span style="margin-right: 5px"
                ><i class="el-icon-check"></i></span
              >检查网络环境是否稳定
            </p>
            <p style="color: #8d8a8a">
              <span style="margin-right: 5px"
                ><i class="el-icon-check"></i></span
              >检查VPN，并确定关闭
            </p>
            <p style="color: #8d8a8a">
              <span style="margin-right: 5px"
                ><i class="el-icon-check"></i></span
              >请尝试在电脑-网络中，把dns改成223.5.5.5 或者 223.6.6.6 后重试
            </p>
            <div style="text-align: right; margin: 0">
              <button
                @click="problem_visible = false"
                type="button"
                class="ant-button blue-button"
                style="font-size: 12px; margin-top: 20px"
              >
                <span>知道了</span>
              </button>
            </div>
          </div>

          <div slot="reference" class="problem">
            <i class="el-icon-question" style="color: #e3912b"></i>
            帮助
          </div>
        </el-popover>
      </div>
      <div class="upload_progress_file">
        <ul>
          <li
            v-for="(item, index) in temp_array"
            v-bind:key="index"
            class="upload_progress_file_item"
            :class="{
              upload_progress_file_animate: index == upload_active_index,
            }"
          >
            <span class="upload_progress_item_filename" :title="item.name">{{
              item.name
            }}</span>
            <span
              class="upload_progress_item_filesize"
              :title="upload_size(index)"
              >{{ upload_size(index) }}</span
            >
            <span
              class="upload_progress_item_status"
              :style="{ color: item.result == 'fail' ? '#f44336' : '' }"
              :title="upload_status(index)"
            >
              <i
                class="el-icon-circle-check"
                style="color: #5bda92"
                v-if="item.result == 'ok'"
              ></i
              >{{ upload_status(index) }}</span
            >
          </li>
        </ul>
      </div>
    </el-dialog>

    <!--分类编辑弹出框-->
    <template>
      <el-dialog
        title="编辑分类"
        center
        :visible.sync="class_edit.dialogTableVisible"
        width="750px"
      >
        <template v-if="class_edit.tableData.length != 0">
          <el-table
            :data="class_edit.tableData"
            style="width: 100%"
            row-key="value"
            :default-expand-all="false"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column label="一级类名" width="320">
              <template slot-scope="scope">
                <span style="margin-left: 10px"
                  >({{ scope.row.layer }}) {{ scope.row.label }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="
                    class_edit_function('rename_sub_class', { row: scope.row })
                  "
                  >重命名</el-button
                >
                <el-button
                  size="mini"
                  @click="
                    class_edit_function('add_sub_class', {
                      row: scope.row,
                      title: '添加子类名',
                    })
                  "
                  v-if="scope.row.layer < 3"
                  >添加子类</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="
                    class_edit_function('delete_sub_class', {
                      row: scope.row,
                    })
                  "
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else>
          <el-empty description="空空如也~" :image-size="120"></el-empty>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button
            style="margin-right: 30px"
            size="small"
            @click="
              class_edit_function('add_sub_class', {
                row: {},
                title: '添加一级类名',
              })
            "
            >添加一级类名</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click.stop="class_edit_function('save_and_quit', { menu: false })"
            >保存更改</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        :title="class_edit.dialog_title"
        center
        :visible.sync="class_edit.dialogFormVisible"
        width="500px"
        :close-on-click-modal="false"
      >
        <div style="margin: 0 30px">
          <el-input
            type="text"
            v-model="class_edit.input_value"
            placeholder="请输入您的自定义类名"
          >
            <span slot="suffix" class="slot_span"
              ><span
                :style="{
                  color: class_edit.count_input_word > 12 ? '#F56C6C' : '',
                }"
                >{{ class_edit.count_input_word }}</span
              >/12</span
            >
          </el-input>
          <ul class="explain_list">
            <li>分类最大层数为3层</li>
            <li>每层最多可添加100个自定义分类</li>
            <li>该分类将显示在您的网站、小程序中</li>
            <li>分类名称最大支持6个汉字或12个英文</li>
          </ul>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button
            size="small"
            @click.stop="class_edit.dialogFormVisible = false"
            style="margin-right: 30px"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click.stop="class_edit_function('confirm_input_change')"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </template>
  </div>
</template>
<script>
import plugins from '../assets/js/plugin';
import class_edit from '../assets/js/class_edit';
export default {
  props: {
    current_menu: Object,
    current_menu_index: Number
  },
  data () {
    return {
      series: {
        edit_name: '基础设置',
        array: ['基础设置']
      },

      //父组件传参
      menu_index: this.current_menu_index,

      // batch_upload参数
      dialogFormVisible: false,
      temp_array: [],
      upload_array: [],
      upload_result: {   // 数量统计
        flag: 0,
        success: 0
      },
      upload_active_index: -1,
      problem_visible: false,
      is_save: false, //当销毁组件时，如果此时未点击保存，则删除临时图片

      // 保存提示框
      save_prompt: {
        save_prompt_dialogVisible: false,
        save_prompt_content: '',
        save_loading: false,
        save_over: false,  //保存完成，不管是否成功，直接返回
      },

      //分类编辑控制参数
      class_edit: {
        dialogTableVisible: false,
        el_cascader_value: [],
        options: [],
        tableData: [],
        input_value: '',
        count_input_word: 0,
        class_row: {},
        dialog_title: '标题',
        dialogFormVisible: false,
        isResouceShow: 0
      },
    }
  },
  computed: {
    upload_message () {
      return function () {
        let flag = 0
        for (let i = 0; i < this.temp_array.length; i++) {
          if (this.temp_array[i].result.length != 0) {
            flag++
          }
        }
        if (flag != this.temp_array.length) {
          return '正在上传\xa0\xa0' + flag + '/' + this.temp_array.length
        }
        else {
          return '上传完成，' + '成功' + this.upload_result.success + '张，失败' + this.upload_result.fail + '张'
        }
      }
    },
    upload_size: function () {
      // 值得注意的是，如parameter 放在了 要计算的属性后面，取到的是vm实例。
      return function (index) {
        if (this.temp_array[index].size < 1) {
          return ((this.temp_array[index].size * 1000).toFixed(1)) + 'KB'
        }
        else {
          return (this.temp_array[index].size.toFixed(1)) + 'MB'
        }
      }
    },
    upload_status: function () {

      return function (index) {
        if (this.temp_array[index].result == 'ok') {
          return '上传成功'
        }
        else {
          if (this.temp_array[index].result == 'fail') {
            return this.temp_array[index].reason
          }
          else {
            if (this.temp_array[index].percentage == 0) {
              return '排队中'
            }
            else {
              if (this.temp_array[index].percentage == -2) {
                return '正在压缩'
              }
              else {
                return (this.temp_array[index].percentage - 1) + '%'
              }
            }
          }
        }
      }

    }
  },
  mounted () {
    // es6语法，拼接数组 https://blog.csdn.net/cristina_song/article/details/82805444
    // 在最初服务器端设置menu的时候，分类菜单的class_edit.options被置为[],所以是一定存在的
    this.class_edit.options.push(...this.current_menu.options)
    // 监听浏览器或者标签关闭事件,备注：刷新也会触发，关闭浏览器或者刷新时，如果删除的图片过多，可能无法及时删除全部。
    window.addEventListener('beforeunload', () => this.cancel_upload())
  },
  destroyed () {
    console.log('destroyed:temp_batch_upload)')
    if (this.is_save == false) {
      this.cancel_upload()
    }
  },
  watch: {
    'class_edit.input_value' (newVal, oldVal) {  // 对象具体属性的watch可以直接用引号把属性括起来，就可以实现对象中特定属性的监听事件：
      console.log('input_value:', newVal, oldVal)
      this.class_edit.count_input_word = newVal.length
      //中文、中文标点、全角字符按2长度，英文、英文符号、数字按1长度计算
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
      let mat = newVal.match(cnReg);
      if (mat) {
        this.class_edit.count_input_word = mat.length * 2 + (newVal.length - mat.length)
      } else {
        this.class_edit.count_input_word = newVal.length
      }
    }
  },
  methods: {
    choose_series_item (item) {
      this.series.edit_name = item
    },
    cancel_upload: async function (data) {
      if (data) {
        this.$emit('cancel_upload', data)
      }
      // 删除所有临时文件夹图片
      for (let i = 0; i < this.upload_array.length; i++) {
        let delete_item = this.upload_array[i]
        // this.print('delete_item:', delete_item)
        let file = delete_item.url.url
        let file_temp = file.split('?')[0] //截取参数前面的文件路径
        let file_name = 'temp/' + file_temp.substr(file_temp.lastIndexOf('/') + 1)  //这里肯定是来自temp文件夹
        await plugins.oss_delete(this, file_name)
      }
    },
    delete_img (index) {
      let delete_item = this.upload_array.splice(index, 1)[0]
      // this.print('delete_item:', delete_item)
      let file = delete_item.url.url
      let file_temp = file.split('?')[0] //截取参数前面的文件路径
      let file_name = 'temp/' + file_temp.substr(file_temp.lastIndexOf('/') + 1)  //这里肯定是来自temp文件夹
      plugins.oss_delete(this, file_name)
    },
    save_and_quit () {
      this.print('current_menu:', this.current_menu)
      let that = this
      this.print('el_cascader_value:', this.class_edit.el_cascader_value)
      // 没有必要加下面判断，element-ui已经做了处理
      /*
      if (this.save_prompt.save_loading == true) {
        console.log('正在保存')
        return
      }
      */
      if (this.upload_array.length == 0) {
        this.$alert('请至少上传一张图片', '提示', {
          confirmButtonText: '知道了',
        })
        return
      }
      if (this.class_edit.el_cascader_value.length == 0) {
        this.$alert('请选择分类', '提示', {
          confirmButtonText: '知道了',
          closeOnClickModal: true
        })
        return
      }
      let works = []
      let temp = that.$store.state.accept_data
      // this.print('temp:', temp)
      this.print('上传数据:', this.upload_array)
      for (let i = 0; i < this.upload_array.length; i++) {
        let graphic_introduction_cover = {}
        //以下5个属性为小程序端使用
        graphic_introduction_cover.path = this.upload_array[i].url.url
        graphic_introduction_cover.from = 'temp'
        graphic_introduction_cover.type = 'image'
        graphic_introduction_cover.percent = 0
        graphic_introduction_cover.size = this.upload_array[i].size
        //以下两个属性为桌面端使用
        graphic_introduction_cover.name = this.upload_array[i].name
        graphic_introduction_cover.thumbnail_image_url = ''
        let work = {}
        work.graphic_introduction_cover = graphic_introduction_cover
        work.subject = this.class_edit.el_cascader_value[0]
        work.subject2 = this.class_edit.el_cascader_value[1] || ''
        work.subject3 = this.class_edit.el_cascader_value[2] || ''
        work.AuthorizerAppid_id = temp.AuthorizerAppid_id
        work.menu_value = this.current_menu.value
        works.push(work)
      }
      this.print('works:', works)
      this.save_prompt.save_loading = true

      let content = {
        type: 'upload_temp_list_show_one_batch_image',
        phone: temp.phone,
        session: that.$store.state.session,
        works: works
      }
      plugins.bms_update(that, content)
        .then(() => {
          this.save_prompt.save_loading = false
          this.$confirm('上传成功', '', {
            confirmButtonText: '知道了',
            showCancelButton: false,
            closeOnClickModal: false,
            showClose: false,
            type: 'success'
          }).then(() => {
            that.cancel_upload('返回上页')
          })
        })
        .catch((err) => {
          this.save_prompt.save_loading = false
          this.$confirm('上传失败，请稍后再试', '提示', {
            confirmButtonText: '知道了',
            showCancelButton: false,
            closeOnClickModal: false,
            type: 'error'
          }).then(() => {
            that.cancel_upload('返回上页')
          })
          console.log('plugins.bms_update catch:', err)
        })
    },
    file_change: async function (e) {
      // 不要将整个files作为参数传递给插件中的函数，将导致处理异常。
      var that = this
      console.log('e:', e.target.files)
      var files = e.target.files
      if (files.length === 0) {
        console.log('No file selected.')
        return
      }
      if (this.upload_array.length + files.length > 100) {
        console.log('too much.')
        this.$message({
          showClose: true,
          message: '每次最多能上传100张图片',
          type: 'warning'
        })
        // 这里也需要重置,原因见函数末尾注释
        this.$refs.upload.value = null
        return
      }
      // 数据初始化
      this.temp_array = []
      this.upload_result.success = 0
      this.upload_result.fail = 0
      this.upload_active_index = -1
      for (let i = 0; i < files.length; i++) {
        let obj = {
          size: files[i].size / 1024 / 1024,
          name: files[i].name,
          percentage: 0,
          result: '',  //'ok' or 'fail'
          url: {},
          reason: '',
          from: 'temp',
          content: '',  // 正文内容
          layout: 'left', // 文字对齐方式
          img_describe: '', //图片底部文字描述
        }
        for (let j = 0; j < this.upload_array.length; j++) {
          if (this.upload_array[j].name == obj.name) {
            obj.result = 'fail'
            obj.reason = '文件已上传，同批次无法重复上传'
            break
          }
        }
        this.temp_array.push(obj)
      }
      this.dialogFormVisible = true
      var oss_sign = await plugins.oss_sign(that)
      if (oss_sign.result != 'ok') {
        return
      }
      for (let i = 0; i < this.temp_array.length; i++) {
        this.upload_active_index = i
        if (this.temp_array[i].result == 'fail') {
          // 前面处理过的重复文件直接不处理
          this.upload_result.fail++
        }
        else {
          let obj = await plugins.image_batch_upload_oss(that, files[i], oss_sign.data, i)
          this.temp_array[i].size = obj.size
          this.temp_array[i].result = obj.result
          this.temp_array[i].url = obj.url
          this.temp_array[i].reason = obj.reason
          // 统计成功和失败数量
          if (this.temp_array[i].result == 'ok') {
            this.upload_result.success++
            this.upload_array.push(this.temp_array[i])
          }
          if (this.temp_array[i].result == 'fail') {
            // 统计上传失败的文件
            this.upload_result.fail++
          }
        }
      }
      // 重新置-1，消除当前处理条目的背景色
      this.upload_active_index = -1
      this.print('temp_array:', this.temp_array)
      console.log('upload_array:', this.upload_array)
      // this.dialogFormVisible=false

      // 选用change事件触发上传文件，当两次选择相同文件的时候发现change事件没有触发,因此上传完成后把input的value值置空
      this.$refs.upload.value = null
    },
    submitUpload () {
      this.$refs.upload.click();
    },

    // 分类编辑控制函数
    class_edit_function (type, param) {
      param = param || null
      console.log('param:', param)
      class_edit.class_edit_function(this, type, param)
    },
  }
}
</script>
<style scoped>
@import '../assets/scss/upload.scss';
</style>
