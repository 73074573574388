/* eslint-disable */
import lrz from "lrz";

function requestLogin(that, phone_number, password, type = 'login', _id = null) {
    return new Promise(function (resolve, reject) {
        let param = new URLSearchParams()
        param.append('username', phone_number)
        param.append('password', password)
        param.append('type', type)
        param.append('_id', _id)
        that.$ajax({
                url: '/zhitui/zuomian_login',
                method: 'post',
                data: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                // console.log(ses.substring(0, 3))
                if (response.status === 200 && typeof (response.data) === 'object') {
                    // console.log(response.status)
                    // 除了数据属性，Vue 实例还暴露了一些有用的实例属性与方法。它们都有前缀 $，以便与用户定义的属性区分开来
                    let data = response.data
                    that.print('return data:', data)
                    if (data.result != 'success') {
                        that.$message({
                            showClose: true,
                            message: data.result,
                            type: 'error'
                        })
                        // 这里只将密码重置，不对帐号做处理。
                        that.password = ''
                        return reject()
                    } else {
                        if (type == 'login') {
                            that.is_choose_account = true
                            that.$store.commit('SET_TOKEN', data.session)
                            for (let i = 0; i < data.account_info.length; i++) {
                                if (data.account_info[i].child_account_info != null) {
                                    // 授权账号
                                    that.child_account.push(data.account_info[i])
                                } else {
                                    // 主账号
                                    that.primary_account.push(data.account_info[i])
                                }
                            }
                            that.print('that.primary_account:', that.primary_account)
                            return resolve()
                        }
                        if (type == 'refresh_time') {
                            console.log('refresh_time')
                            return resolve()
                        }
                    }
                } else {
                    that.$message({
                        showClose: true,
                        message: '请求错误，请稍后再试',
                        type: 'error'
                    })
                    return reject()
                }
            })
            .catch((response) => {
                console.log(response)
                that.$message({
                    showClose: true,
                    message: '网络错误！请稍后再试',
                    type: 'error'
                })
                return reject()
            })
    })
};

// 更新数据
function bms_update(that, content, text = '') {
    return new Promise(function (resolve, reject) {
        let param = new URLSearchParams()
        param.append('content', JSON.stringify(content))
        // 如果完整引入了 Element，那么 Vue.prototype 上会有一个全局方法 $loading，它的调用方式为：this.$loading(options)
        var loadingInstance = that.$loading({
            lock: true,
            text: text,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })
        that.$ajax({
                url: '/zhitui/zuomian_update',
                method: 'post',
                data: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                loadingInstance.close()
                if (response.status === 200 && typeof (response.data) === 'object') {
                    let data = response.data
                    // console.log('return data:', data)
                    if (data.result != 'success') {
                        that.$message({
                            showClose: true,
                            message: data.result,
                            type: 'error'
                        })
                        if (data.result == '登录过期') {
                            that.$router.replace('/login')
                        }
                        console.log('1')
                        return reject({})
                    } else {
                        /*
                        that.$store.commit('SET_TOKEN', data.session)
                        that.$store.commit('SET_accept_data', data.main_account_info)
                        console.log("accept_data:", data.main_account_info)
                        */
                        return resolve(data)
                    }
                } else {
                    that.$message({
                        showClose: true,
                        message: '请求错误，请稍后再试',
                        type: 'error'
                    })
                    console.log('2')
                    return reject({})
                }
            })
            .catch((response) => {
                setTimeout(() => {
                    loadingInstance.close()
                }, 300)
                console.log(response)
                that.$message({
                    showClose: true,
                    message: '网络错误！请稍后再试',
                    type: 'error'
                })
                return reject()
            })
    })
};

// 更新数据
function bms_update_no_loading(that, content, text = '') {
    return new Promise(function (resolve, reject) {
        let param = new URLSearchParams()
        param.append('content', JSON.stringify(content))
        that.$ajax({
                url: '/zhitui/zuomian_update',
                method: 'post',
                data: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.status === 200 && typeof (response.data) === 'object') {
                    let data = response.data
                    // console.log('return data:', data)
                    if (data.result != 'success') {
                        that.$message({
                            showClose: true,
                            message: data.result,
                            type: 'error'
                        })
                        if (data.result == '登录过期') {
                            that.$router.replace('/login')
                        }
                        console.log('1')
                        return reject({})
                    } else {
                        /*
                        that.$store.commit('SET_TOKEN', data.session)
                        that.$store.commit('SET_accept_data', data.main_account_info)
                        console.log("accept_data:", data.main_account_info)
                        */
                        return resolve(data)
                    }
                } else {
                    that.$message({
                        showClose: true,
                        message: '请求错误，请稍后再试',
                        type: 'error'
                    })
                    console.log('2')
                    return reject({})
                }
            })
            .catch((response) => {
                console.log(response)
                that.$message({
                    showClose: true,
                    message: '网络错误！请稍后再试',
                    type: 'error'
                })
                return reject()
            })
    })
};

// 请求数据
function bms_download(that, content) {
    return new Promise(function (resolve, reject) {
        let param = new URLSearchParams()
        param.append('content', JSON.stringify(content))
        // 如果完整引入了 Element，那么 Vue.prototype 上会有一个全局方法 $loading，它的调用方式为：this.$loading(options)
        that.$ajax({
                url: '/zhitui/zuomian_download',
                method: 'post',
                data: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.status === 200 && typeof (response.data) === 'object') {
                    let data = response.data
                    // console.log('return data:', data)
                    if (data.result != 'success') {
                        that.$message({
                            showClose: true,
                            message: data.result,
                            type: 'error'
                        })
                        if (data.result == '登录过期') {
                            that.$router.replace('/login')
                        }
                        return reject()
                    } else {
                        /*
                        that.$store.commit('SET_TOKEN', data.session)
                        that.$store.commit('SET_accept_data', data.main_account_info)
                        console.log("accept_data:", data.main_account_info)
                        */
                        setTimeout(() => {
                            return resolve(response.data)
                        }, 500)
                    }
                } else {
                    that.$message({
                        showClose: true,
                        message: '请求错误，请稍后再试',
                        type: 'error'
                    })
                    console.log('2')
                    return reject({})
                }
            })
            .catch((response) => {
                setTimeout(() => {}, 300)
                console.log(response)
                that.$message({
                    showClose: true,
                    message: '网络错误！请稍后再试',
                    type: 'error'
                })
                return reject()
            })
    })
};

// base64 转 blob
// 参见：url、base64、blob相互转换方法：https://zhuanlan.zhihu.com/p/57700185
// Blob欠缺对二进制数据的细节操作能力，比如如果如果要具体修改某一部分的二进制数据，Blob显然就不够用了，而这种细粒度的功能则可以由下面介绍的ArrayBuffer来完成。
function base64ToBlob(base64) {
    let byteString;

    // 这下面的条件判断有必要吗？
    if (base64.split(',')[0].indexOf('base64') >= 0) {
        // atob() 方法用于解码使用 base-64 编码的字符串
        byteString = atob(base64.split(',')[1]);
    } else {
        byteString = unescape(base64.split(',')[1]);
    }

    // base64数据格式：data:image/jpeg;base64,/9j/4AAQSkZ
    // 下面得到的是image/jpeg
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)

    for (let i = 0; i < byteString.length; i++) {
        // Unicode 编码 charCodeAt() 方法可返回指定位置的字符的 Unicode 编码
        ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ia], {
        type: mimeString
    });

    return blob;
};

// 获取oss授权上传签名
/*
function ossSign1(that, file) {
    return new Promise(function (resolve, reject) {
        let content = {}
        content.type = 'oss_sign'
        let param = new URLSearchParams()
        param.append('username', 'dkeiYdjcnccnswwwoepsa')
        param.append('content', JSON.stringify(content))
        that.$ajax({
                url: '/zhitui/zuomian_signature',
                method: 'post',
                data: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                // console.log('oss_sign response:', response.data)
                if (response.status === 200 && typeof (response.data) === 'object') {
                    uploadOss(that, response.data, file).then(value => {
                        return resolve({
                            result: 'ok',
                            oss_sign: response.data,
                            img_url_signed: value.data
                        })
                    }).catch((err) => {
                        console.log('upload err:', err)
                        return reject({
                            result: 'error'
                        })
                    })
                } else {
                    console.log('获取用户信息失败')
                    return reject({
                        result: 'error'
                    })
                }
            })
            .catch((response) => {
                console.log(response)
                return reject({
                    result: 'error'
                })
            })

    })
};
*/

// 上传至oss
function uploadOss(that, sign, file, index) {
    // 参见PostObject：https://help.aliyun.com/document_detail/31988.html?spm=a2c4g.11186623.6.1688.1de83ea7EVqneV
    let filename = sign.dirPath + new Date().getTime() + Math.floor(Math.random() * 10000) + '.png'
    var form = new FormData()
    form.append('key', filename)
    form.append('policy', sign.policy)
    form.append('OSSAccessKeyId', sign.OSSAccessKeyId)
    form.append('signature', sign.signature)
    form.append('success_action_status', '200')
    form.append('file', file)
    return new Promise((resolve, reject) => {
        that.$ajax.post(sign.host, form, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                onUploadProgress: e => {
                    if (e.lengthComputable) {
                        that.temp_array[index].percentage = parseInt((e.loaded / e.total) * 100)
                    }
                }
            })
            .then((response) => {
                // 这里不能做response.status === 200 && typeof (response.data) === 'object'判断，oss返回empty string，只能做response.status === 200判断
                if (response.status === 200) {
                    // limit:指定当目标缩放图大于原图时是否进行缩放。1（默认值）：表示不按指定参数进行缩放，直接返回原图。0：按指定参数进行缩放。
                    signatureUrl(filename, 'image/resize,w_980,limit_0', that).then(value => {
                        return resolve({
                            result: 'ok',
                            data: value
                        })
                    }).catch((err) => {
                        console.log('signatureUrl err:', err)
                        return reject({
                            result: 'error'
                        })
                    })
                } else {
                    return reject({
                        result: 'error'
                    })
                }
            })
            .catch((response) => {
                console.log(response)
                return reject({
                    result: 'error'
                })
            })
    })
};

//生成图片带签名的地址
function signatureUrl(filename, process, that) {
    return new Promise(function (resolve, reject) {
        let content = {}
        content.type = 'get_image_signed_url'
        content.phone = that.$store.state.accept_data.phone
        content.session = that.$store.state.session
        content.filename = filename
        content.process = process
        let param = new URLSearchParams()
        param.append('username', 'dkeiYdjcnccnswwwoepsa')
        param.append('content', JSON.stringify(content))
        that.$ajax({
                url: '/zhitui/zuomian_oss',
                method: 'post',
                data: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                // console.log('get signatureUrl response:', response.data)
                if (response.status === 200 && typeof (response.data) === 'object') {

                    if (response.data.result && response.data.result == '登录过期') {
                        that.$message({
                            showClose: true,
                            message: '登录过期',
                            type: 'error'
                        })
                        that.$router.replace('/login')
                    }
                    return resolve(response.data)
                } else {
                    console.log('获取用户信息失败')
                    return reject('error')
                }
            })
            .catch((response) => {
                console.log('error:', response)
                return reject('error')
            })
    })
};

// 图片压缩
function lrz_compress_image(file, max_size) {
    return new Promise(function (resolve) {
        lrz(file, {})
            .then(res => {
                console.log('lrz_compress_image res.fileLen:', res.fileLen / 1024 / 1024)
                if (res.fileLen / 1024 / 1024 > max_size) {
                    return resolve({
                        result: 'fail',
                        reason: '压缩失败'
                    })
                } else {
                    var fileBlob = base64ToBlob(res.base64)
                    return resolve({
                        result: 'ok',
                        fileBlob: fileBlob,
                        size: res.fileLen / 1024 / 1024
                    })
                }
            })
            .catch((err) => {
                console.log('err:', err)
                return resolve({
                    result: 'fail',
                    reason: '压缩失败'
                })
            })
    })
};

// 获取oss授权上传签名
function oss_sign(that) {
    return new Promise(function (resolve, reject) {
        let content = {}
        content.type = 'oss_sign'
        content.phone = that.$store.state.accept_data.phone
        content.session = that.$store.state.session
        let param = new URLSearchParams()
        param.append('username', 'dkeiYdjcnccnswwwoepsa')
        param.append('content', JSON.stringify(content))
        that.$ajax({
                url: '/zhitui/zuomian_oss',
                method: 'post',
                data: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                // console.log('oss_sign response:', response.data)
                if (response.status === 200 && typeof (response.data) === 'object') {
                    if (response.data.result && response.data.result == '登录过期') {
                        that.$message({
                            showClose: true,
                            message: '登录过期',
                            type: 'error'
                        })
                        that.$router.replace('/login')
                    }
                    return resolve({
                        result: 'ok',
                        data: response.data
                    })
                } else {
                    console.log('获取用户信息失败')
                    return reject({
                        result: 'error'
                    })
                }
            })
            .catch((response) => {
                console.log(response)
                return reject({
                    result: 'error'
                })
            })

    })
};



//删除oss图片
function oss_delete(that, file) {
    return new Promise(function (resolve, reject) {
        let content = {}
        content.type = 'oss_delete_img'
        content.phone = that.$store.state.accept_data.phone
        content.session = that.$store.state.session
        content.filename = file
        let param = new URLSearchParams()
        param.append('username', 'dkeiYdjcnccnswwwoepsa')
        param.append('content', JSON.stringify(content))
        that.$ajax({
                url: '/zhitui/zuomian_oss',
                method: 'post',
                data: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                // console.log('oss_sign response:', response.data)
                if (response.status === 200 && typeof (response.data) === 'object') {
                    if (response.data.result && response.data.result == '登录过期') {
                        that.$message({
                            showClose: true,
                            message: '登录过期',
                            type: 'error'
                        })
                        that.$router.replace('/login')
                    }
                    return resolve({
                        result: 'ok'
                    })
                } else {
                    console.log('获取用户信息失败')
                    return reject({
                        result: 'error'
                    })
                }
            })
            .catch((response) => {
                console.log(response)
                return reject({
                    result: 'error'
                })
            })

    })
};

//批量上传图片至Oss
async function image_batch_upload_oss(that, file, oss_sign, index) {
    var max_size = 0.8 // 超过max_size则压缩，单位MB
    console.log('file:', file)
    let current_size = file.size / 1024 / 1024
    console.log('current_size:', current_size)
    if (current_size > 20) {
        return Promise.resolve({
            result: 'fail',
            reason: '文件大小超过20M',
            size: current_size,
            url: ''
        })
    }
    if (current_size < max_size) { // 大小不超过max_size的压缩反而会变大，因此大小低于max_size的图片不压缩，直接上传
        let temp_upload = await uploadOss(that, oss_sign, file, index)
        if (temp_upload.result == 'error') {
            return Promise.resolve({
                result: 'fail',
                reason: '上传失败',
                size: current_size,
                url: ''
            })
        } else {
            return Promise.resolve({
                result: 'ok',
                url: temp_upload.data,
                size: current_size,
                reason: ''
            })
        }
    } else {
        that.temp_array[index].percentage = -2 // 表示正在压缩
        let temp_lrz = await lrz_compress_image(file, max_size)
        if (temp_lrz.result == 'ok') {
            let temp_upload = await uploadOss(that, oss_sign, temp_lrz.fileBlob, index)
            if (temp_upload.result == 'error') {
                return Promise.resolve({
                    result: 'fail',
                    reason: '上传失败',
                    size: current_size,
                    url: ''
                })
            } else {
                return Promise.resolve({
                    result: 'ok',
                    url: temp_upload.data,
                    size: temp_lrz.size,
                    reason: ''
                })
            }
        } else {
            return Promise.resolve({
                result: 'fail',
                reason: '压缩失败',
                size: current_size,
                url: ''
            })
        }
    }
};

// 宽高读取和判断
function judge_width_height(file) {
    return new Promise(function (resolve) {
        let judge_img_src = URL.createObjectURL(file)
        let judge_img = new Image()
        judge_img.src = judge_img_src
        judge_img.onload = function () {
            if (judge_img.width / judge_img.height > 8 || judge_img.width / judge_img.height < 1 / 8) {
                console.log('长短边比例不能超过8:1,压缩可能会非常模糊')
                return resolve({
                    result: 'fail',
                    reason: '长短边比例不能超过 8:1'
                })
            } else {
                return resolve({
                    result: '',
                    resaon: ''
                })
            }
        }
    })
};

const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}

const formatTime_ymd = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    /*
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    */

    // return [year].map(formatNumber) + '年' + [month].map(formatNumber) + '月' + [day].map(formatNumber) + '号'

    return [year, month, day].map(formatNumber).join('-')
};

const formatTime_ymdhms = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    // return [year].map(formatNumber) + '年' + [month].map(formatNumber) + '月' + [day].map(formatNumber) + '号'

    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
};

export default {
    requestLogin,
    bms_update,
    bms_update_no_loading:bms_update_no_loading,
    bms_download,
    oss_sign,
    oss_delete,
    image_batch_upload_oss,
    judge_width_height,
    formatTime_ymd: formatTime_ymd,
    formatTime_ymdhms: formatTime_ymdhms
};