<template>
  <div class="control_panel">
    <section class="header">
      <div class="header_bg">
        <div class="header_con">
          <a class="header_logo">
            <span>我的工作台</span>
          </a>
          <div style="flex: 1 1 0%"></div>
          <nav class="header_main-nav">
            <a class="header_main-nav_son" v-on:click="log_out('/login')">
              <i class="iconfont icontuichu"></i><span>退出登录</span>
            </a>
          </nav>
        </div>
      </div>
    </section>
    <section class="Jcenter">
      <div style="width: 150px; padding-bottom: 50px; float: left">
        <div class="app-side-menu">
          <ul class="ant_menu">
            <template v-for="(visitor, index) in menu">
              <template v-if="visitor.name != '下划线'">
                <li
                  :key="index"
                  class="ant-menu-item"
                  v-bind:class="{
                    ant_menu_item_active: menu_isActive_index == index,
                  }"
                  v-on:click="change_isActive(index)"
                >
                  <i class="iconfont" :class="[visitor.class]"></i
                  >{{ visitor.name }}
                </li>
              </template>
              <template v-else>
                <li class="ant-menu-item-divider" :key="index"></li>
              </template>
            </template>
          </ul>
        </div>
      </div>
      <div style="margin-left: 150px; overflow: hidden; position: relative">
        <div class="work_page" style="padding-bottom: 52px; margin: 0px auto">
          <div class="work_page_head">
            <span class="link">工作台</span>
            <span style="margin: 0px 8px; color: rgba(0, 0, 0, 0.45)"
              >&gt;</span
            >
            <span
              class="market_header_title_gray"
              :class="{
                market_header_title_gray_active_link:
                  current_menu_son.length > 0,
              }"
              @click="change_operation_event('返回上页')"
              >{{ current_menu }}</span
            >
            <span
              v-if="current_menu_son.length > 0"
              style="margin: 0px 8px; color: rgba(0, 0, 0, 0.45)"
              >&gt;</span
            >
            <span
              class="market_header_title_gray"
              v-if="current_menu_son.length > 0"
              >{{ current_menu_son }}</span
            >
          </div>
          <div class="work_page_area">
            <TempListShowOne
              v-if="template_name == 'list_show_one'"
              :current_menu="menu[menu_isActive_index]"
              :current_menu_index="menu_isActive_index"
              @image_upload="change_operation_event"
            />
            <TempListShowTwo
              v-if="template_name == 'list_show_two'"
              v-bind:current_menu="menu[menu_isActive_index]"
              v-bind:current_menu_index="menu_isActive_index"
              v-on:cancel_upload="change_operation_event"
            />
            <TempWangZhan
              v-if="template_name == 'wang_zhan'"
              v-bind:page="page"
              v-bind:page-size="pageSize"
              v-bind:total="total"
              v-bind:max-page="maxPage"
            />
            <TempBatchUpload
              v-if="template_name == 'batch_upload'"
              v-bind:current_menu="menu[menu_isActive_index]"
              v-bind:current_menu_index="menu_isActive_index"
              v-on:cancel_upload="change_operation_event"
            />
            <TempTeamManagement
              v-if="template_name == 'team_management'"
              :current_menu="menu[menu_isActive_index]"
              :current_menu_index="menu_isActive_index"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="footer"></section>
    <!--这里可以加入公共方法组件-->
  </div>
</template>
<script>
import { WOW } from 'wowjs';
// @ is an alias to /src
// 常规加载 
// import TempListShowOne from "@/components/Temp_list_show_one.vue";
// import TempListShowTwo from "@/components/Temp_list_show_two.vue";
import TempWangZhan from "@/components/Temp_wang_zhan.vue";
import TempBatchUpload from "@/components/Temp_batch_upload.vue";
import TempTeamManagement from "@/components/Temp_team_management";
export default {
  name: 'controlpanel',
  data () {
    return {
      phone_number: '',
      password: '',
      page: 1,  //显示的是哪一页，动态量
      pageSize: 10, //每一页显示的数据条数，常数
      total: 180, //数据总数，动态量
      maxPage: 5,  //最大页数,常数
      first_show: true,
      menu: [],
      //下面给两个参数用于页面记录，整个网站最多二层深度
      current_menu: '小程序',// 当前菜单名
      listshow_first_open: false, // 由于希望模板之间的切换不用重新加载数据，需要使用v-show,导致一开始页面渲染时所有数据被渲染，而此时
      //由于指向当前的模板不是listshow,导致无法正确获得<Templistshow>中props的数据从而导致console报错。这里加入变量进行首次if控制,已启用，简单
      // 起见模板全部用v-if
    }
  },
  components: {
    // 懒加载 区别于上面的常规加载，可以在谷歌开发者工具中的network看到按需加载了Js和css,而常规加载在页面加载时已经全部加载
    'TempListShowOne': () => import('@/components/Temp_list_show_one.vue'),
    'TempListShowTwo': () => import('@/components/Temp_list_show_two.vue'),
    TempWangZhan,
    TempBatchUpload,
    TempTeamManagement
  },
  computed: {
    // 通过vuex定义几个全局变量，然后通过computed引入html渲染
    template_name () { // 页面类型：'listshow','wangzhan'等
      return this.$store.state.controlpanel_template_name   //这里返回的是一个值，也可以返回一个函数，见Temp_batch_upload.vue
    },
    menu_isActive_index () { // 页面类型：'listshow','wangzhan'等
      return 1 * this.$store.state.controlpanel_menu_isActive_index   //这里返回的是一个值，也可以返回一个函数，见Temp_batch_upload.vue
    },
    current_menu_son () { //当前二级菜单名
      return this.$store.state.controlpanel_current_menu_son  //这里返回的是一个值，也可以返回一个函数，见Temp_batch_upload.vue
    },
  },
  // vue实例的数据对象data在钩子函数created()执行的时候已经有了
  created () {
    // 如果直接在新的标签页的地址栏中打开本页面由于session已经重置，将跳转到登陆页面
    if (!this.$store.state.session || !this.$store.state.accept_data) {
      this.$router.replace('/login')
      return
    }
    // 如果在当前标签页中刷新页面，由于内存中保存的数据可能都已经被覆盖重置，所以需要读取sessionStorage中的数据
    // 参见： vuex存储和本地存储(localstorage、sessionstorage)的区别 https://www.cnblogs.com/jsanntq/p/9288144.html
    // 另外，这些赋值不能放在Mounted中，否则子组件中传值速度低于页面渲染速度会报错
    this.menu = this.$store.state.accept_data.bms_menu
    this.current_menu = this.menu[this.$store.state.controlpanel_menu_isActive_index].name
  },
  mounted () {
    // 参考 https://www.jianshu.com/p/eff637c7ffc9
    var wow = new WOW({
      live: false
    })
    wow.init()
  },
  methods: {
    change_isActive (index) {
      this.$store.commit('controlpanel_menu_isActive_index', index)
      this.$store.commit('controlpanel_template_name', this.menu[index].template)
      // 切换一级菜单时，二级菜单置空值
      this.$store.commit('controlpanel_current_menu_son', '')
      this.current_menu = this.menu[index].name
    },
    change_operation_event (data) {
      // 这里只改变template_name的值进行操作选择(例如图片上传等），menu_isActive_index的值并未改变，仍然指向对应的菜单
      // console.log('change_operation_event:', data)

      // 匹配对应模板
      switch (data) {
        // 不同名称可以使用相同的模板，例如single_upload和batch_upload都匹配的是Tempupload模板，只是向模板传递的参数不一样
        case '单图上传':
          // 改变vuex值，会相应刷新computed中的值，但是sessionstorage中的值改变并不会得到响应
          this.$store.commit('controlpanel_template_name', 'batch_upload')
          this.$store.commit('controlpanel_current_menu_son', data)
          break;
        case '批量上传':
          this.$store.commit('controlpanel_template_name', 'batch_upload')
          this.$store.commit('controlpanel_current_menu_son', data)
          break;
        case '返回上页':   //其实直接返回到了目录页，不管有几层
          this.change_isActive(this.menu_isActive_index)
          break;
        default:
      }
    },
    log_out (page) {
      // 清空session
      this.$store.commit('SET_TOKEN', '')
      this.$store.commit('SET_accept_data', {})
      this.$router.replace(page)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../assets/scss/icon.scss';
@import '../assets/scss/common.scss';
@import '../assets/scss/controlpanel.scss';
</style>
